    <template>
    <div>
        <div v-if="stream.session.PrimaryMedia && stream.session.PrimaryMedia.MediaType == 'LiveWebSource'">
            <input type="url"
                   class="form-control"
                   v-model="uri"
                   placeholder="Embed URL (e.g. http://embed.youtube.com)">
        </div>
        <div v-if="stream.session.PrimaryMedia && stream.session.PrimaryMedia.MediaType == 'TractusSource'">
            <small class="badge rounded-pill bg-light text-dark" 
                        v-for="broadcaster in items" v-bind:key="broadcaster">
                    <span v-if="broadcaster && broadcaster != ''">
                    {{getAttendeeNameFromCode(broadcaster)}}
                    <i @click="removeBroadCaster(broadcaster)" class="ms-auto bi bi-x-circle"></i> 
                    </span>
            </small>
            <div v-if="!showSelect">
                <button type="button" @click="addContact">add from contact info</button>
                <button type="button" @click="showSelect = !showSelect">select attendee</button>
            </div>
            <div v-if="showSelect">
                <select v-model="attendeeCode">
                    <option v-for="attendee in showDetails.Attendees" :value="attendee.Code">
                        {{attendee.DisplayName}}
                    </option>
                </select>
                <button type="button" @click="addBroadcaster">Add</button>
            </div>
        </div>
    </div>   
</template>
<script>
export default {
    props:['stream','showDetails', 'roleCode'],

    computed: {
        items() {
            return this.roleCode == "Producers"
                ? this.stream.stream.Producers
                : this.stream.stream.Broadcasters;
        }
    },

    watch: {
        'stream.session.PrimaryMedia.MediaType'(newVal, oldval){
            this.showSelect = false;
            let embedPath = "";
            this.uri = embedPath;
            this.stream.session.PrimaryMedia.Uri = embedPath;
        },
        uri(newVal, oldVal) {
            if(oldVal == null){
                return;
            }
            if(newVal == null){
                return;
            }
            
            this.stream.ModifiedSession = true;
            
            if(newVal.startsWith('https://www.youtube.com/watch?')) {
                //https://www.youtube.com/embed/JL9w0jvWS2g?autoplay=1

                let embedPath = newVal.replace('/watch?v=', '/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.uri = embedPath;

                this.stream.session.PrimaryMedia.Uri = embedPath;
                this.stream.session.PrimaryMedia.AutoPlay = true;

            } else if (newVal.startsWith("https://youtu.be/")) {
                // https://youtu.be/X-SMvEmL1Xg
                let embedPath = newVal.replace('https://youtu.be/', 'https://www.youtube.com/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.uri = embedPath;

                this.stream.session.PrimaryMedia.Uri = embedPath;
                this.stream.session.PrimaryMedia.AutoPlay = true;
            } else if (newVal.startsWith('https://www.twitch.tv/')) {
                let channelName = newVal.split('/').pop();

                let embedPath = `https://player.twitch.tv/?volume=0.5&!muted&channel=${channelName}`;

                this.uri = embedPath;

                this.stream.session.PrimaryMedia.Uri = embedPath;
                this.stream.session.PrimaryMedia.AutoPlay = true;
            }
            else{
                this.stream.session.PrimaryMedia.Uri = this.uri;
            }

            
        }
    },

    methods:{
        removeBroadCaster(code){
            if(this.roleCode == 'Producers') {
                this.stream.stream.Producers.splice(this.stream.stream.Producers.indexOf(code), 1);
            } else {
                this.stream.stream.Broadcasters.splice(this.stream.stream.Broadcasters.indexOf(code), 1);
            }
        },
        addBroadcaster(){
            if(this.stream.stream){

                if(this.roleCode == "Producers") {

                    if(this.stream.stream.Producers.includes(this.attendeeCode) == false){
                        this.stream.stream.Producers.push(this.attendeeCode);
                        this.stream.Modified = true;
                        this.$emit("update:stream.Modified", true);
                    }


                } else {

                    if(this.stream.stream.Broadcasters.includes(this.attendeeCode) == false){
                        this.stream.stream.Broadcasters.push(this.attendeeCode);
                        this.stream.Modified = true;
                        this.$emit("update:stream.Modified", true);
                    }


                }

            }
        },
        getAttendeeNameFromCode(code){
            let attendee = this.showDetails.Attendees.find(x => x.Code == code);
            if(attendee){
                return attendee.DisplayName;
            }
            return null;
        },
        addContact(){
            this.$emit("addContact",this.stream);
        }
    },

    data(){ 
        return{
            uri: null,
            showSelect: false,
            attendeeCode: null
        }
    },

    created: function(){
        this.uri = this.stream.session.PrimaryMedia.Uri;
    }
}
</script>
