<template>
    <video ref="videoPlayer"
            v-show="src"
            controls
            playsinline
            preload="false"
            crossorigin="anonymous">
    </video>
</template>
<script>
import Hls from 'hls.js'

export default {
    props: [
        'src'
    ],

    watch: {
        src() {
            this.initializePlayer();
        },
    },

    data() {
        return {
            hls: null,
        }
    },

    methods: {
        onHlsError(e, d) {
            console.error("Error on HLS.", e, d);
            let hls = this.hls;

            if(d.type == Hls.ErrorTypes.NETWORK_ERROR) {
                if(d.fatal) {
                    setTimeout(() => hls.startLoad(), 3000);
                }
            } else if(d.type == Hls.ErrorTypes.MEDIA_ERROR) {
                if(d.fatal) {
                    hls.recoverMediaError();
                }
            } else {
                if(d.fatal) {
                    hls.destroy();
                }
            }
        },

        initializePlayer() {

            if(this.hls) {
                this.hls.destroy();
                this.hls = null;
            }

            if(!this.src) {
                return;
            }

            let v = this.$refs.videoPlayer;
            let videoUrl = this.src;

            let hlsSupported = Hls.isSupported();
            let useNativePlayer = 
                v.canPlayType('application/vnd.apple.mpegurl')
                || videoUrl.toLowerCase().endsWith(".webm")
                || videoUrl.toLowerCase().endsWith(".mp4");

            if(hlsSupported && !useNativePlayer) {

                let hls = new Hls({
                    debug: true,
                    maxBufferLength: 20,
                    maxBufferSize: 20
                });

                this.hls = hls;

                hls.on(Hls.Events.ERROR, this.onHlsError);
                
				hls.loadSource(videoUrl);
				hls.attachMedia(v);

            } else if(useNativePlayer) {

                v.crossOrigin = 'anonymous';
                v.addEventListener('error', e => {
                    this.showPlayer = false;
                    this.loading = false;
                });


                v.src = this.videoSource;
            }
        }
    },

    beforeDestroy() {
        if(this.hls) {
            this.hls.destroy();
            this.hls = null;
        }
    },

    mounted() {
        this.initializePlayer();
    }
}
</script>