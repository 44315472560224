<template>
    <form class="form-group">

        <div class="d-flex justify-content-between mt-1">
            <button type="button"
                    class="btn btn-secondary"
                    @click="OnCancel">
                Cancel
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-primary"
                        @click="onSave">
                    Save
                </button>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-sm-4">
                <div class="profile-photo-preview" @click="onChoose">
                    <img :src="editAttendee.ContactInfo.PhotoUrl"
                            class="img-fluid"
                            :acceptFileType="'image/*'"
                            v-if="editAttendee.ContactInfo.PhotoUrl">
                </div>
                <file-picker ref="picker" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="editAttendee.ContactInfo.PhotoUrl"/>
                <i v-if="editAttendee.ContactInfo.PhotoUrl !== ''" @click="clearImage" class="ms-auto bi bi-x-circle"></i>

                <div class="form-check">
                    <label class="form-check-label" title="Hides the attendee inside the app. Disables chat.">
                        <input :disabled="submitting"
                                v-model="editAttendee.DoNotContact"
                                type="checkbox"
                                class="form-check-input"/>
                        Do Not Contact (app)
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input :disabled="submitting" 
                                v-model="editAttendee.PleaseContact"
                                type="checkbox"
                                class="form-check-input"/>
                        Featured Attendee</label>
                </div>
                <select class="form-select form-select-sm" v-model="editAttendee.RoleCode">
                    <option selected disabled hidden :value="null">(Please Select...)</option>
                    <option v-for="role in roleTypes" :value="role.Type">
                        {{role.Name}}
                    </option>
                </select>

                <div class="form-floating mt-2">
                    <input :disabled="true" 
                        class="form-control form-control-sm"
                        v-model="editAttendee.Code" />
                    <label>Attendee Code</label>
                </div>
                
                <!-- 2021-01-11 Mike commented this out because if its blank it autogenerates the code on the server, it causes issues when passing the attendee to  -->
                <!-- submitting -->
                
                <div class="form-floating mt-2">
                    <input :disabled="submitting" 
                            class="form-control form-control-sm"
                            v-model="editAttendee.SignInPin" />             
                    <label>Sign in PIN</label>
                </div>
                
            </div>

            <div class="col">
                <div class="form-floating mt-2">
                    <input :disabled="submitting"
                        class="form-control"
                        v-model='editAttendee.FirstName' 
                        @change="onUpdateAttendeeDisplayName"
                        ref="firstNameField" />
                    <label @click="outputAttendeeJsonToConsole">
                        First Name
                    </label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting"
                        class="form-control"
                        @change="onUpdateAttendeeDisplayName"
                        v-model='editAttendee.LastName' />
                    <label>
                        Last Name
                    </label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting"
                        class="form-control form-control-sm"
                        v-model='editAttendee.DisplayName' />
                    <label>
                        Preferred Name
                    </label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting" 
                        v-model="editAttendee.PrimaryEmail"
                        class="form-control form-control-sm"/>
                    <label>Primary E-mail Address</label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting" 
                        v-model="editAttendee.PrimaryPhone"
                        class="form-control form-control-sm"/>
                    <label>SMS Contact Number</label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting"
                        class="form-control form-control-sm"
                        v-model='editAttendee.ContactInfo.Title' />
                    <label>
                        Title
                    </label>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting"
                        class="form-control form-control-sm"
                        v-model='editAttendee.ContactInfo.SubTitle' />
                    <label>
                        Subtitle
                    </label>
                </div>

                <div class="form-floating mt-2">
                    <textarea :disabled="submitting" 
                            class="form-control form-control-sm" 
                            v-model="editAttendee.ContactInfo.Bio">
                    </textarea>
                    <label>Biography</label>
                </div>
            </div>
        </div>

        <hr>

        <div class="col-12 mb-3">
            <div class="row">
                <div v-for="aCategory in showCategories" 
                    :key="aCategory._LocalCode"
                    class="col-4">
                    <div class="form-check">
                        <label :class="{'fw-bold': aCategory.Selected }"
                            class="form-check-label">
                            <input type="checkbox" 
                                class="form-check-input"
                                v-model="aCategory.Selected">
                            {{aCategory.Name}}
                        </label>
                    </div> 
                </div>
            </div>
        </div>

        <hr>

        <div class="col-12 mb-3">
            <div class="row mb-3">
                <div class="col">
                    <h5>Custom Categories</h5>
                </div>
                <div class="col-auto">
                    <button class="btn btn-primary btn-sm"
                            @click="addCustomCategory"
                            type="button">
                        <i class="bi bi-plus"></i>
                    </button>
                </div>
            </div>
            <div class="row mb-2" 
                 v-for="customCategory in customCategories"
                 :key="customCategory._LocalCode">
                <div class="col">
                    <div class="form-floating">
                        <input class="form-control" v-model="customCategory.Name" placeholder="Category Name">
                        <label>Category Name</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating">
                        <input class="form-control" v-model="customCategory.Code" placeholder="Category Code">
                        <label>Category Code</label>
                    </div>
                </div>
                <div class="col-auto d-flex align-items-center">
                    <button class="btn btn-primary me-2" 
                            @click="customCategory.IsHidden = !customCategory.IsHidden;"
                            type="button">
                        <i class="bi" 
                           :class="{'bi-eye-slash-fill': customCategory.IsHidden,
                                    'bi-eye-fill': !customCategory.IsHidden}"></i>
                    </button>
                    <button class="btn btn-danger" 
                            @click="removeCustomCategory(customCategory._LocalCode)"
                            type="button">
                        <i class="bi bi-trash"></i>
                    </button>
                </div>
            </div>
        </div>

        <hr>

        <div class="mb-2">
            <button type="button" @click.prevent="onAddContactInfo" id="addBtn" class="btn btn-primary">
                <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Contact Info/Social Media
            </button>
        </div>
        
        <vue-slicklist :useDragHandle="true" v-model="editAttendee.ContactInfo.ContactInfo" lockAxis="y" 
                       v-if="editAttendee.ContactInfo.ContactInfo && tractusLinks">
            <vue-slickitem class="row" 
                            v-for="(contactInfo, i) in editAttendee.ContactInfo.ContactInfo" 
                            :key="i" 
                            :index="i">
                            
                <i class="col-auto bi-grip-vertical" v-handle></i>
                <div class="col" v-if="contactInfo">
                    <contact-info-editor :contactInfo="contactInfo"
                                        :selectTypes="contactInfoTypes"
                                        :submitting="submitting"
                                        :show="show"
                                        v-on:delete-contact-info="onDeleteContactInfo(editAttendee.ContactInfo.ContactInfo, contactInfo)"
                                        :isContact="true"
                                        :destinations="tractusLinks">
                    </contact-info-editor>
                </div>
            </vue-slickitem>
        </vue-slicklist>
        <loading v-else />

        <div class="mb-2">
            <button type="button" @click.prevent="onAddContactInfo" id="addBtn" class="btn btn-primary">
                <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Contact Info/Social Media
            </button>
        </div>


        <hr>
       
        <media-information :mediaInformation.sync="editAttendee.PrimaryMedia"
                           :show="show"
                           v-if="editAttendee.PrimaryMedia">
        </media-information>

        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                Oops! It looks like something happened. Please double-check and try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
        </div>
    </form>
</template>
<style>
.profile-photo-preview {
    height: 200px;
    border: 1px solid darkblue;
    overflow: hidden;
}

.profile-photo-preview > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import Category from '../../models/Category'
import CommonTypes from '../common.types'

export default {
    props: ['attendee', 'show', 'doNotSave','destinations'],
    mixins: [
        Token,
        Common,
        CommonTypes
    ],
    
    computed: {
        customCategories() {
            return this.editAttendeeCategories.filter(x => x.IsCustom == true);
        },
        showCategories(){
            return this.editAttendeeCategories.filter(x => x.IsCustom == false);
        }
    },


    data() {
        return {
            sessions:null,
            tractusLinks: null,
            editAttendee: null,

            editAttendeeCategories: null,
            validCode:true,
            uploadBus: new Vue(),

            roleTypes:[
                {Type:"Attendee",Name:"Attendee"},
                {Type:"Administrator", Name: "Administrator"},
            ],
           
        }
    },

    async created() {
        this.editAttendee = this.cloneObject(this.attendee);
        this.tractusLinks = this.destinations;
        this.getSessionsForShow();
        if(!this.destinations){
            await this.getTractusLinkDestinationsAsync(this.show.Code);
            this.tractusLinks = this.tractusLinkDestinations;
        }

        if(typeof this.editAttendee.PrimaryMedia === 'undefined'
            || this.editAttendee.PrimaryMedia == null){
            Vue.set(this.editAttendee, "PrimaryMedia", {
                Code: this.getNewGuid(),
                Uri: "",
                MediaType: "Video",
                AutoPlay: false,
                ThumbnailUri: "",
                Title: "",
                Description: "",
                PlayAfterExit: false
            });
        }

        if(!this.editAttendee.RoleCode) {
            Vue.set(this.editAttendee, 'RoleCode', 'Attendee');
        }

        if(typeof this.editAttendee.DoNotContact === 'undefined') {
            Vue.set(this.editAttendee, "DoNotContact", false);
        }

        if(typeof this.editAttendee.PleaseContact === 'undefined') {
            Vue.set(this.editAttendee, "PleaseContact", false);
        }

        if(!this.editAttendee.Breakouts) {
            Vue.set(this.editAttendee, "Breakouts", []);
        }

        if(!this.editAttendee.PrimaryPhone) {
            Vue.set(this.editAttendee, "PrimaryPhone", '');
        }

        if(!this.editAttendee.VendorCanScan) {
            Vue.set(this.editAttendee, "VendorCanScan", []);
        }

        this.editAttendeeCategories = [];

        for(var i = 0; i < this.show.AttendeeCategories.length; i++){
            this.editAttendeeCategories.push({
                Name: this.show.AttendeeCategories[i].Name, 
                Code:this.show.AttendeeCategories[i].Code, 
                Order:this.show.AttendeeCategories[i].Order, 
                AlwaysShow:this.show.AttendeeCategories[i].AlwaysShow, 
                Selected: false,
                _LocalCode: this.uuidv4(),
                IsCustom: false})
        }
        
        for(var i = 0; i < this.editAttendee.Categories.length; i++){
            let attendeeCategory = this.editAttendee.Categories[i];
            let categoryIndex = this.editAttendeeCategories.findIndex(x => x.Code == attendeeCategory.Code);
            if(categoryIndex != -1){
                this.editAttendeeCategories[categoryIndex].Selected = true;
            }
            else{
                this.editAttendeeCategories.push({
                Name: attendeeCategory.Name, 
                Code: attendeeCategory.Code, 
                Order:attendeeCategory.Order, 
                AlwaysShow:attendeeCategory.AlwaysShow, 
                Selected: true,
                IsCustom: true,
                IsHidden: attendeeCategory.IsHidden,
                _LocalCode: this.uuidv4()})
            }
        }
    },

    mounted() {
        Vue.nextTick(() => {
            this.highlightFirstField();
        })
    },

    methods: {
        removeCustomCategory(localCode){
            this.editAttendeeCategories.splice(this.editAttendeeCategories.findIndex(x => x._LocalCode == localCode), 1);
        },
        addCustomCategory(){
            var category = new Category();
            category.Selected = true;
            category.IsCustom = true;
            category.IsHidden = true;
            
            category._LocalCode = this.uuidv4();
            this.editAttendeeCategories.push(category)

        },
        outputAttendeeJsonToConsole() {
            console.log("EDIT ATTENDEE DEBUG --- ", this.editAttendee);
            console.log("ORIGINAL ATTENDEE ---", this.attendee);
        },

        onUpdateAttendeeDisplayName() {
            if(this.editAttendee.DisplayName && this.editAttendee.DisplayName.length > 0) {
                return;
            }

            if(!this.editAttendee.FirstName || !this.editAttendee.LastName) {
                return;
            }

            this.editAttendee.DisplayName = `${this.editAttendee.FirstName.trim()} ${this.editAttendee.LastName.trim()}`;
        },

        highlightFirstField() {
            this.$refs.firstNameField.focus();
            this.$refs.firstNameField.setSelectionRange(0, 9999);
        },

        async getSessionsForShow(){
             try {
                let r = await this.tryGet(`/api/show/${this.show.Code}/sessions`);
            
                this.sessions = r.Result;

            } catch(ex) {
                console.error(ex);
            }
        },
        getNewGuid() { 
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { 
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); 
                return v.toString(16); 
            }); 
        }, 
        //also used on poll.transfer.to.page
        onAddContactInfo(value, property, type) {
            var toAdd = {
                Details: "",
                DisplayText: "",
                Type: ""
            };

            if(!this.editAttendee.ContactInfo.ContactInfo) {
                Vue.set(this.editAttendee.ContactInfo, 'ContactInfo', []);
            } 

            if(value && property) {
                value = value.trim();
                toAdd[property] = value;
                toAdd.Type = 'Website';
            }


            if(type) {
                toAdd.Type = type;

                if(type == 'Email' && value) {
                    toAdd.DisplayText = value;
                }
            }

            this.editAttendee.ContactInfo.ContactInfo.push(toAdd);
            this.$emit('update:attendee', this.editAttendee);
        },

        //also used on poll.transfer.to.page
        flashProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editAttendee, propertyName, value);
            this.$emit('update:attendee', this.editAttendee);
        },

        //used on poll.transfer.to.page
        flashMediaProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editAttendee.PrimaryMedia, propertyName, value);
            this.$emit('update:attendee', this.editAttendee);
        },

        //used on poll.transfer.to.page
        flashContactInfoProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editAttendee.ContactInfo, propertyName, value);
            this.$emit('update:attendee', this.editAttendee);
        },

        //used on poll.transfer.to.page
        flashContactInfoItemProperty(value, index, propertyName) {
            value = value.trim();
            Vue.set(this.editAttendee.ContactInfo.ContactInfo[index], propertyName, value);
            this.$emit('update:attendee', this.editAttendee);
        },
        
        onDeleteContactInfo(deleteFrom,toBeDeleted) {
            deleteFrom.splice(deleteFrom.indexOf(toBeDeleted), 1);
            this.$emit('update:attendee', this.editAttendee);
        },

        validateForm: function() {

            this.validateErrors = [];
            this.validCode = true;

            if(this.editAttendee.PrimaryMedia.Uri){
                if(!this.editAttendee.PrimaryMedia.Uri.replace(/\s/g, '').length){
                    this.validateErrors.push("Uri cannot contain only spaces.");
                } 
                if(this.editAttendee.PrimaryMedia.Title && !this.editAttendee.PrimaryMedia.Title.replace(/\s/g, '').length){
                    this.validateErrors.push("Video Title cannot contain only spaces.");
                }
            }

            var str = this.editAttendee.FirstName;
            if (this.editAttendee.FirstName && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("First name cannot contain only spaces.");
            }

            str = this.editAttendee.LastName;
            if (this.editAttendee.LastName && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Attendee Last Name cannot contain only spaces.");
            }
           
            if(!this.editAttendee.RoleCode) {
                this.validateErrors.push("Attendee Role not selected.");
            }
            
            return this.validateErrors.length === 0;
        },
        clearImage(){
            this.editAttendee.ContactInfo.PhotoUrl= '';
        },

        onFileSelected(fileName){
            console.log('ON FILE SELECTED --- ', fileName);
            this.editAttendee.ContactInfo.PhotoUrl = fileName;
        },

        async onSave() {
            this.submitting = true;
            this.editAttendee.Categories = [];
            this.editAttendee.Visits = [];
            for(var i = 0; i < this.editAttendeeCategories.length; i++){
                var category = new Category();
                if(this.editAttendeeCategories[i].Selected){
                    category.Name = this.editAttendeeCategories[i].Name;
                    category.Code = this.editAttendeeCategories[i].Code.toUpperCase().replace(" ","");
                    category.Order = this.editAttendeeCategories[i].Order;
                    category.AlwaysShow = this.editAttendeeCategories[i].AlwaysShow;
                    category.IsHidden = this.editAttendeeCategories[i].IsHidden;
                    this.editAttendee.Categories.push(category)
                }
            }
            this.editAttendee.Show = {
                Id: this.show.Id,
                Code: this.show.Code
            };
            this.editAttendee.Code = this.editAttendee.Code.toUpperCase();

            if(!this.validateForm()) {
             
                this.submitting = false;
                return;
            }

            if(this.doNotSave) {
                this.$emit('saved', this.editAttendee);
            } else {
                try{
                    let r = await this.tryPost('/api/attendee', JSON.stringify(this.editAttendee), 'application/json');
                
                    if(r.Errors.length === 0) {
                        this.$emit('saved', r.Result);
                    }
                }
                catch(ex){
                    console.log(ex);
                }
                
            }
            this.submitting = false;

        },
        OnCancel(){
            this.submitting = false;
            this.$emit('cancel');
        },
        onChoose(){
            // console.log(this.show);
            this.$refs.picker.openPicker();
        },
    },
    
}
</script>
