// Tractus.Data.ShowTheme
// Generated on 2019-11-12 12:53:01 PM by Elias

class ShowTheme {
	//public String[] HomeBackgrounds
	HomeBackgrounds = [];
	//public String StyleSheet
	StyleSheet = null;
}
export default ShowTheme
