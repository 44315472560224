<template>
    <div class="d-flex justify-content-center">
        <div>
            <div class="d-flex justify-content-center h3">
                {{title}}
            </div>
            <div class="row" v-for="row in rows">
                <div :class="{'col col-auto': idx == 0, 'col': idx != 0}" v-for="(column, idx) in row.columns">
                    <router-link tag="button" class="list-group-item list-group-item-action" :to="column.link">
                        <div class="row h5 mb-0 ms-1">
                            {{column.linkTitle}}
                        </div>
                        <div class="row mx-1">
                            {{column.linkSubtitle}}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],

    watch: {
        showDetails(to, from) {
            this.show = to;
        }
    },

    methods: {

    },

    data() {
        return {
            show: null,
            title: null,
            rows: []           
        }
    },

    created: function() {
        this.show = this.showDetails;
        
        // add here
        if(this.$route.name == "vendorLanding"){
            this.title = "Companies and Sponsors";
            this.rows = [
                { columns: [
                    //link wasn't working with name if its done this way so im using path
                    {link: "pages/details",
                    linkTitle: "Companies and Sponsors",
                    linkSubtitle: "Manage your Exhibitors/Sponsors."
                    },
                    {link: "pages/categories",
                    linkTitle: "Categories",
                    linkSubtitle: "Manage your Exhibitors/Sponsors categories."
                    }
                  ] 
                }
            ];
        }
        if(this.$route.name == "sessionLanding"){
            this.title = "Sessions";
            this.rows = [
                { columns: [
                    //link wasn't working with name if its done this way so im using path
                    {link: "sessions/details",
                    linkTitle: "Sessions",
                    linkSubtitle: "Create and edit sessions."
                    },
                    {link: "sessions/categories",
                    linkTitle: "Categories",
                    linkSubtitle: "Create and manage the session categories."
                    },
                    {link: "sessions/calendar",
                    linkTitle: "Calendar",
                    linkSubtitle: "Prototype calendar."
                    }
                  ] 
                }
            ];
        }
    },

    errorCaptured (err, vm, info) {
        this.error = `${err.stack}\n\nfound in ${info} of component`;
        console.log(this.error);
        return false;
    }
}
</script>