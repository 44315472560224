// Tractus.Data.Show
//  inherits from Tractus.Data.TractusEntity.
// Generated on 2019-11-12 12:53:01 PM by Elias

class Show {
	//public String Name
	Name = null;
	//public DateTime StartDateTime
	StartDateTime = new Date();
	//public DateTime EndDateTime
	EndDateTime = new Date();
	//public String ShowHeaderImagePath
	ShowHeaderImagePath = null;
	//public Tractus.Data.AgendaItemCategory[] VendorCategories
	VendorCategories = [];
	//public Tractus.Data.AgendaItemCategory[] SessionCategories
	SessionCategories = [];
	//public Tractus.Data.Category[] AttendeeCategories
	AttendeeCategories = [];
	//public Section[] Sections
	Sections = [];
	//public ShowTheme Theme
	Theme = null;
	//public Dictionary<String,Company[]> Companies
	Companies = {};
	//public String Id
	Id = null;
	//public String Code
	Code = null;
	//public JObject AdditionalProperties
	AdditionalProperties = null;
	//public bool IsPublicShow
	IsPublicShow = false;
}
export default Show
