<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <div class="row">
            <div class="col-md-5">
                <h4 class="text-center">Zoom Meetings</h4>
                <div class="row justify-content-md-center">
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn"
                                    :class="[mode == 'SESSIONS' ? 'btn-primary' : 'btn-secondary']"
                                    @click="mode = 'SESSIONS'">
                                Sessions
                            </button>
                            <button class="btn"
                                    :class="[mode == 'VENDORS' ? 'btn-primary' : 'btn-secondary']"
                                    @click="mode = 'VENDORS'">
                                Pages
                            </button>
                        </div>
                    </div>
                    <div class="col-auto">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-success"
                                type="button"
                                @click="saveChanges">
                            Save
                        </button>
                    </div>
                </div>
                <div class="mt-2">
                    <div class="row">
                        <div class="col">
                            <select v-model="selectedEntity"
                                    class="form-control">
                                <option v-for="entity in newEntityArray"
                                        :key="entity.Code"
                                        :value="entity">
                                    {{entity.DisplayName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary"
                                    type="button"
                                    @click="addMeeting">
                                <i class="bi bi-plus-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="list-group mt-2">
                        <button class="list-group-item list-group-item-action"
                            type="button"
                            :class="[selectedMeeting == meeting ? 'active' : '']"
                            v-for="meeting in meetingsList"
                            :key="meeting.EntityCode"
                            @click="selectedMeeting = meeting">
                            {{lookupMeeting(meeting)}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row">
                    <div class="col" v-if="selectedMeeting">
                        <h4>{{lookupMeeting(selectedMeeting)}}</h4>

                        <div class="form-floating mb-2">
                            <input class="form-control" 
                                   v-model="selectedMeeting.MeetingCode"
                                   placeholder="Meeting Code">
                            <label>
                                Meeting Code
                            </label>
                        </div>

                        <div class="form-floating">
                            <input class="form-control" 
                                   v-model="selectedMeeting.Password"
                                   placeholder="Password">
                            <label>
                                Password
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.DisableInvite">
                                Disable Invite
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.DisableCallOut">
                                Disable Call Out
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.DisableRecord">
                                Disable Record
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.DisableJoinAudio">
                                Disable Join Audio
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.AudioPanelAlwaysOpen">
                                Audio Panel Always Open
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportAV">
                                Is Support AV
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportChat">
                                Is Support Chat
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportQA">
                                Is Support QA
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportCC">
                                Is Support CC
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportPolling">
                                Is Support Polling
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportBreakout">
                                Is Support Breakout
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.ScreenShare">
                                Allow Screen Share
                            </label>
                        </div>

                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.VideoHeader">
                                Video Header
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.ShowMeetingHeader">
                                Show Meeting Header
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsSupportNonverbal">
                                Is Support Nonverbal
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.IsWebinar">
                                Is Webinar
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" v-model="selectedMeeting.AskBeforeEnter">
                                Ask Before Join
                            </label>
                        </div>


                        <div class="form-group">
                            <input readonly
                                   class="form-control"
                                   :value="`/zoom.html?a={{attendeeCode}}&s={{showCode}}&e={{entityTypeCode}}&c={{entityCode}}`">
                        </div>
                        <button class="btn btn-danger"
                                @click="deleteMeeting()"
                                type="button">
                            <i class="bi bi-x-lg"></i><span class="ms-2">Delete</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import Vendor from '../models/Vendor'
import { getAttendeeAppUrl } from './environment.hci'

export default {
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    computed: {
        meetingsList() {
            return this.mode == 'SESSIONS'
                ? this.meetings.sessions
                : this.meetings.vendors;
        },

        newEntityArray() {
            return this.mode == 'SESSIONS'
                ? this.sessions
                : this.vendors;
        },

        getAttendeeAppUrl() {
            return getAttendeeAppUrl();
        }
    },

    methods: {  
        
        lookupMeeting(meeting) {
            let lookupArray = this.mode == 'SESSIONS'
                ? this.sessions
                : this.vendors;

            let value = lookupArray.find(x => x.Code == meeting.EntityCode);
            if(!value) {
                return "Unknown - Entity Not Found";
            }

            return value.DisplayName;
        },

        async deleteMeeting() {
            this.submitting = true;
            let r = await this.tryDelete(`/api/zoom/${this.showCode}/meetings/${this.selectedMeeting.EntityTypeCode}/${this.selectedMeeting.EntityCode}`);
            this.submitting = false;

            let lookupArray = this.mode == 'SESSIONS'
                ? this.meetings.sessions
                : this.meetings.vendors;

            let index = lookupArray.indexOf(this.selectedMeeting);

            lookupArray.splice(index, 1);

            this.selectedMeeting = null;
        },

        async saveChanges() {
            this.submitting = true;

            let toSubmit = [...this.meetings.sessions, ...this.meetings.vendors];

            await this.tryPost(`/api/zoom/${this.showCode}/meetings`,
                                JSON.stringify(toSubmit), 
                                'application/json');

            await this.build();

            this.submitting = false;
        },

        addMeeting() {
            if(!this.selectedEntity || this.meetingsList.find(x => x.EntityCode == this.selectedEntity.Code)) {
                return;
            }

            let lookupArray = this.mode == 'SESSIONS'
                ? this.meetings.sessions
                : this.meetings.vendors;

            let newMeeting = {
                EntityTypeCode: this.mode.slice(0, -1),
                EntityCode: this.selectedEntity.Code,
                MeetingCode: '',
                Password: '',
                DisableInvite: true,
                DisableCallOut: false,
                DisableRecord: true,
                DisableJoinAudio: false,
                AudioPanelAlwaysOpen: false,
                IsSupportAV: true,
                IsSupportChat: true,
                IsSupportQA: false,
                IsSupportCC: false,
                IsSupportPolling: false,
                IsSupportBreakout: true,
                ScreenShare: true,
                IsWebinar: false,                
                AskBeforeEnter: false,
            }

            lookupArray.push(newMeeting);

            this.selectedMeeting = newMeeting;
        },

        async build() {
            this.submitting = true;
                       
            try {
                let result = await this.tryGet(`/api/zoom/${this.showCode}/meetings`);

                let meetingsForSessions = result.Result.filter(x => x.EntityTypeCode == 'SESSION');
                let meetingsForVendors = result.Result.filter(x => x.EntityTypeCode == 'VENDOR');

                this.meetings.sessions = meetingsForSessions;
                this.meetings.vendors = meetingsForVendors;

                result = await this.tryGet(`/api/resources/${this.showCode}?t=SESSIONS;VENDORS`)

                this.sessions = result.Result.SESSIONS;
                this.vendors = result.Result.VENDORS;

            } catch {
                alert("Could not load meetings. Try again later.");
            }
            
            this.submitting = false;
        },
    },

    data() {
        return {
            meetings: {
                sessions: [],
                vendors: [],
            },
            sessions: [],
            vendors: [],

            selectedEntity: null,
            selectedMeeting: null,
            mode: 'SESSIONS'
        }
    },

    mounted: function() {
        this.build();
    },
}
</script>