import { getAttendeeAppUrl, getLandingAppUrl } from './environment.hci'
import { debounce } from 'vue-debounce'

export default {
    data() {
        return {
            previewWindowRef: null,
            selectedCategories: null,
            queueUpdate: null,
            selectedPreviewAttendee: null,
            previewAttendeeList: [],
            loadingAttendeeList: false,
        }
    },

    methods: {
        launchPreviewWindow(show) {
            if (this.selectedPreviewAttendee) {
                var attendeeCode = this.selectedPreviewAttendee.Code || "";
                var pin = this.selectedPreviewAttendee.SignInPin ||  "";    
            }
            
            this.previewWindowRef = (show.IsPublicShow) ? window.open(`${getLandingAppUrl()}/#/${show.Code}/home`)
                                                        : window.open(`${getAttendeeAppUrl()}/login?a=${attendeeCode}&p=${pin}&s=${show.Code}&dl=true`);
            
            setTimeout(this.postPreviewUpdate, 1000);
            if (!this.queueUpdate) {
                this.queueUpdate = debounce(this.postPreviewUpdate, "500ms")
            }
        },
        postPreviewUpdate() {
            this.previewWindowRef.postMessage({
                Type: "Passport",
                Attendee: this.getShowPreviewAttendee()
            },"*");
        },
        getShowPreviewAttendee() {
            
        },
        loadPreviewAttendees() {
        },
    }
}