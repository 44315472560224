// Tractus.Data.Discussion.DiscussionArea
//  inherits from Tractus.Data.TractusEntity.
// Generated on 2019-11-12 12:53:01 PM by Elias

class DiscussionArea {
	//public String Name
	Name = null;
	//public String Description
	Description = null;
	//public String TypeCode
	TypeCode = null;
	//public String ShowCode
	ShowCode = null;
	//public Boolean AllowAnonymous
	AllowAnonymous = false;
	//public Boolean AllowPost
	AllowPost = false;
	//public String PublicCode
	PublicCode = null;
	//public Boolean NotifyParticipants
	NotifyParticipants = false;
	//public Boolean InviteOnly
	InviteOnly = false;
	//public Dictionary<String,String> ParticipantRoles
	ParticipantRoles = {};
	//public Tractus.Data.Discussion.DiscussionPost[] Posts
	Posts = [];
	//public String Id
	Id = null;
	//public String Code
	Code = null;
	//public JObject AdditionalProperties
    AdditionalProperties = null;
    VisibleToCategories = [];
    MaxCallers = 50;

    AllowCall = true;

    PhotoUrl = null;

    MuteOnJoin = false;
    
    WebinarFormat = false;    
    DelayBetweenMessageSec = 0;
    
}
export default DiscussionArea
