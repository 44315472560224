class EmailTemplate {
    Footer = null;
	//public String Name
	Name = null;
	//public String Subject
	Subject = null;
	//public NotificationBody[] Body
	Body = null;
	//public Show Show
	ShowCode = null;
	//public String Id
	Id = null;
	//public String Code
	Code = null;
}
export default EmailTemplate
