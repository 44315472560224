// Tractus.Data.Poll
//  inherits from Tractus.Data.TractusEntity.
// Generated on 2019-11-12 12:53:01 PM by Elias

class Poll {
	//public String ShowCode
	ShowCode = null;
	//public String RespondantAttendeeCode
	RespondantAttendeeCode = null;
	//public String RespondantAttendeeName
	RespondantAttendeeName = null;
	//public String TypeCode
	TypeCode = null;
	//public Nullable<System.DateTime> SubmittedDateTime
	SubmittedDateTime = null;
	//public String Notes
	Notes = null;
	//public Boolean IsOpenOverride
	IsOpenOverride = false;
	//public Boolean AllowMultiSubmit
	AllowMultiSubmit = false;
	//public String Name
	Name = null;
	//public Tractus.Data.PollQuestion[] Questions
	Questions = [];
	//public Tractus.Data.Poll[] Responses
	Responses = [];
	//public Boolean AllowAnonymousResponse
	AllowAnonymousResponse = false;
	//public Boolean IsAnonymous
	IsAnonymous = false;
	//public String ConclusionMessage
	ConclusionMessage = null;
	//public String HeaderImage
	HeaderImage = null;
	//public String HeaderText
	HeaderText = null;
	//public String RegistrationNotificationTemplateCode
	RegistrationNotificationTemplateCode = null;
	//public String AttendeeCategoryCode
	AttendeeCategoryCode = null;
	//public String Id
	Id = null;
	//public String Code
	Code = null;
	//public JObject AdditionalProperties
	AdditionalProperties = null;
	//public string PublishResultsCode { get; set; }
	PublishResultsCode = null;
	//public bool AllowEditResponse
	AllowEditResponse = false;
	WelcomeEmailTemplateCode = null;
	VendorCategoryCode = null;
	AutoFulfill = false;
    EnableWebinarAreaForVendor = false;
    LiveCategoryCode = null;
    RedirectToUrl = null;
}
export default Poll
