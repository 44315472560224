<template>
    <loading v-if="submitting && !bundle">

    </loading>
    <div v-else>
        <nav-bar>

        </nav-bar>
        <div class="container-fluid">
            <h4>Create Show / Community</h4>

            <show-basic-details-wizard-page :show="bundle.Show" 
                                            :generateCode="true"
                                            :submitting="submitting">

                <div v-if="bundle.Show.Code">
                    <h5>Attendee Categories</h5>
                    
                    <div class="container-fluid mb-2">
                        <div v-for="category in bundle.Show.AttendeeCategories" 
                             class="form-row border rounded p-3">
                            <div class="form-group col-sm">
                                <label>Name</label>
                                <input class="form-control"
                                    v-model="category.Name">
                                <label>Code</label>
                                <input class="form-control"
                                    v-model="category.Code">
                            </div>
                        </div>

                    </div>
                    <button type="button" 
                            class="btn btn-primary"
                            @click="onAddAttendeeCategory">
                        Add
                    </button>

                    <h5>Page Categories</h5>

                    <div class="container-fluid mb-2">

                        <div v-for="category in bundle.Show.VendorCategories" class="border p-2 mt-1">
                            <div class="form-row">
                                <div class="form-group col-sm">
                                    <label>Name</label>
                                    <input class="form-control"
                                        v-model="category.Name">
                                    <label>Code</label>
                                    <input class="form-control"
                                        v-model="category.Code">
                                </div>
                            </div>
                            <h6>Visible To...</h6>
                            <div class="form-check form-check-inline" v-for="attendeeCategory in bundle.Show.AttendeeCategories">
                                <input class="form-check-input" type="checkbox"
                                    @click="onToggleCategory(category, attendeeCategory)">
                                <label class="form-check-label">
                                    {{attendeeCategory.Name}}
                                </label>
                                
                            </div>     
                        </div>
                    </div>  

                    <div class="btn btn-primary" @click="onAddVendorCategory">
                        Add
                    </div>
                    
                    <h5>Session Categories</h5>

                    <div class="container-fluid mb-2">
                        <div v-for="category in bundle.Show.SessionCategories" class="border p-2 mt-1">
                            <div class="form-row">
                                <div class="form-group col-sm">
                                    <label>Name</label>
                                    <input class="form-control"
                                        v-model="category.Name">
                                    <label>Code</label>
                                    <input class="form-control"
                                        v-model="category.Code">
                                </div>
                            </div>
                            <h6>Visible To...</h6>
                            <div class="form-check form-check-inline" v-for="attendeeCategory in bundle.Show.AttendeeCategories">
                                <input class="form-check-input" type="checkbox" :checked="category.VisibleToAttendeeCategories.find(x=>x.Code == attendeeCategory.Code)"
                                    @click="onToggleCategory(category, attendeeCategory)">
                                <label class="form-check-label">
                                    {{attendeeCategory.Name}}
                                </label>
                            </div>     
                        </div>
                    </div>
                    <div class="btn btn-primary" @click="onAddSessionCategory">
                        Add
                    </div>
                </div>
            </show-basic-details-wizard-page>

            <div class="row mt-4">
                <div class="col text-center">
                    <button type="button" 
                            class="btn btn-lg btn-success"
                            :disabled="!bundle.Show.Code" 
                            @click="onProcessBundle">Create Show
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!submitting" class="wizard-host">

            <div v-if="bundle.Show.Code">
                <div v-if="errors && errors.length > 0">
                    <h4>Errors</h4>
                    <p>We're sorry. Something went wrong.</p>
                    <div v-for="error in errors">
                        {{error}}
                    </div>
                </div>
                <br>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import Show from '../../models/Show'
import moment from 'moment'
import Category from '../../models/Category' 
import AgendaItemCategory from '../../models/AgendaItemCategory' 
import loading from '../loading.vue'

export default {
    components: { loading },

    mixins: [
        Token,
        Common
    ],

    data() {
        return {

            page: 0,
            shows: [],
            copyShowTarget: null,
            candidateAttendeePage: 0,
            targetVendorCategory: null,

            candidateSession: null,
            candidateSessionStartTime: moment(),
            candidateSessionEndTime: moment().add("hours", 1),

            sourceShow: null,

            candidateVendor: null,
            errors: [],
            bundle: null,
            defaultAttendeeCategories: [
                { Name: "Staff", Code: "STF" },
                { Name: "Speakers", Code: "SPK" }
            ]
        }
    },

    methods: {
        onDeleteAttendees(collection){
            var itemsLeftOver = this.bundle.Show.Attendees.filter(item => collection.includes(item.Code) == false);
            // console.log(itemsLeftOver);
            this.bundle.Show.Attendees = itemsLeftOver;
        },
        onFromSourceShow(bundle){
            console.log("original bundle");
            console.log(this.bundle);

            //overwrite bundle code, start/end date, name, header image url with local bundle
            bundle.Show.Code = this.bundle.Show.Code;
            bundle.Show.StartDateTime = this.bundle.Show.StartDateTime;
            bundle.Show.EndDateTime = this.bundle.Show.EndDateTime;
            bundle.Show.Name = this.bundle.Show.Name;
            bundle.Show.ShowHeaderImagePath = this.bundle.Show.ShowHeaderImagePath;

            if(!bundle.EventInfo){
                bundle.EventInfo = this.bundle.EventInfo;
                bundle.EventInfo.Show = bundle.Show;
            }

            console.log("bundle");
            console.log(bundle);
            this.bundle = bundle;
        },

        clearImage(){
            this.eventInfo.EventImageUrl = '';
        },

        insertPlaceHolder: function(textToInsert, editor) {
            // This is a sample of how to insert some text via a toolbar button click.

            var quill = this.$refs.quillEditor.quill;           
         
            var position = quill.getSelection(focus = true);

            if(position) {
                quill.insertText(position.index, '{{' + textToInsert + '}}');
            } else {
                // Editor does not have focus.
            }

        },

        autoGenerateDefaultAttendeeCategories() {
            this.defaultAttendeeCategories.forEach(category => {
                if(!this.bundle.Show.AttendeeCategories.find(x=>x.Code == category.Code)) {
                    let newCategory = new Category();
                    newCategory.Code = category.Code;
                    newCategory.Name = category.Name;

                    this.bundle.Show.AttendeeCategories.push(newCategory);
                }
            });
        },

        onAddAttendeeCategory() {
            console.log("add category");
            console.log(this.bundle);
            let newCategory = new Category();
            newCategory.Name = "";
            if(!newCategory.Code){
                newCategory.Code = this.uuidv4();
            }
            this.bundle.Show.AttendeeCategories.push(newCategory)
        },

        onAddSessionCategory() {
            let newCategory = new AgendaItemCategory();
            newCategory.Name = "";
            if(!newCategory.Code){
                newCategory.Code = this.uuidv4();
            }
            this.bundle.Show.SessionCategories.push(newCategory);
            Vue.set(this.bundle.Show.Sessions, newCategory.Code, [])
        },

        onAddVendorCategory() {
            let newCategory = new AgendaItemCategory();
            newCategory.Name = ""
            if(!newCategory.Code){
                newCategory.Code = this.uuidv4();
            }
            this.bundle.Show.VendorCategories.push(newCategory);
            Vue.set(this.bundle.Show.Vendors, newCategory.Code, [])
        },

        onToggleCategory(category, attendeeCategory) {
            let findResult = category.VisibleToAttendeeCategories.find(x=>x.Code == attendeeCategory.Code);
            if(findResult) {
                category.VisibleToAttendeeCategories.splice(category.VisibleToAttendeeCategories.indexOf(findResult), 1);
            } else {
                category.VisibleToAttendeeCategories.push(attendeeCategory);
            }
        },


        async onProcessBundle() {
            this.submitting = true;
            try{
                let r = await this.tryPost('/api/showwizard', JSON.stringify(this.bundle), 'application/json')
                    
                this.errors = r.Errors;

                if(!this.errors || this.errors.length == 0) {
                    this.$router.replace(`/shows/${this.bundle.Show.Code}`);
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            
        },
    },

    created() {
        if(!this.bundle) {
            this.bundle = {
                Show: new Show(),
                GenerateInvoiceEmail: true
            };

            this.bundle.Show.Id = "0";

            this.bundle.Show.StartDateTime = moment();
            this.bundle.Show.EndDateTime = moment().add(1, 'hours');
        }
    }
}
</script>