<template>
    <div class="list-group-item list-group-item-action d-flex justify-content-betweeen align-items-start">
    <div class="ms-2 me-auto">
       <div class="fw-bold">{{announcement.Subject}}</div>

        <div>{{announcementBody}}</div>
       
        <div class="btn-group mt-2 ">
            <button class="btn btn-sm"
                    :disabled="announcement.Status == 'Published'"
                    :class="[announcement.Status == 'Published' ? 'btn-primary' : 'btn-secondary']"
                    @click="sendClick">
                    Publish
            </button>
            <!-- Do the same as publish for opposite -->
            <button class="btn btn-sm"
                    :disabled="announcement.Status != 'Published'"
                    :class="[announcement.Status == 'Published' ? 'btn-secondary' : 'btn-primary']"
                    @click="unPublishAnnouncement">Unpublish</button>
            <button class="btn btn-sm btn-danger"
                    @click="deleteAnnouncement">Delete</button>
        </div>
    </div>

    <span class="badge bg-secondary rounded-pill">{{announcement.Recipients.length}}</span>
</div>
</template>
<script>
export default {
    props:['announcement'],

    computed: {
        announcementBody() {
            if(!this.announcement){
                return "";
            }
            
            if (this.announcement.Message.length > 140) {
                return this.announcement.Message.substring(0, 140) + '...';
            }
            return this.announcement.Message;
            
            
        },
    },

    methods:{
        sendClick(){
            this.$emit("sendClick",this.announcement);
        },
        unPublishAnnouncement(){
            this.$emit("unPublishAnnouncement",this.announcement);
        },
        deleteAnnouncement(){
            this.$emit("unPublishAnnouncement",this.announcement);
        },
    },

    // data(){ 
    //     return{           
    //         showDeleteButton: false
    //     }
    // },

    // created: function(){
    //     this.showDeleteButton = this.showDelete || false;        
    // }
}
</script>
