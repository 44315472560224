// Tractus.Data.VendorAdditionalInfo
//  inherits from Tractus.Data.EntityAdditionalInfo.
// Generated on 2019-11-12 12:53:01 PM by Elias

class VendorAdditionalInfo {
	//public String PhotoUrl
	PhotoUrl = null;
	//public String Bio
	Bio = null;
	//public ContactInfo[] ContactInfo
	ContactInfo = [];
	//public InfoSection[] Related
	Related = [];
}
export default VendorAdditionalInfo
