// Tractus.Data.ContactInfo
// Generated on 2019-11-12 12:53:01 PM by Elias

class ContactInfo {
	//public String Type
	Type = null;
	//public String Value
	Value = null;
	//public String DisplayText
	DisplayText = null;
	//public String Details
	Details = null;
	//public String IconUrl
	IconUrl = null;
	//public bool IsLocked
	IsLocked = false;
    Style = null;
}
export default ContactInfo
