<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <input placeholder="🔍 Search for Page"
                               class="form-control"
                               v-model="filter">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary"
                                @click="build">
                            <i class="bi bi-arrow-repeat">
                            </i>
                            Refresh
                        </button>
                    </div>
                    <div class="col-auto">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle form-control" 
                                    type="button"
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                    Add Page...
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="category in showDetails.VendorCategories">
                                    <button class="dropdown-item" 
                                            type="button"       
                                       @click="showAddNew(category.Code)">
                                       {{category.Name}}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="badge bg-secondary rounded-pill me-1"
                             v-for="(category, categoryCode) in vendors">
                            {{ getCategoryName(categoryCode) }} ({{ category.length }})
                        </div>

                    </div>
                </div>

                <table class="table table-hover table-striped table-sm">
                    <thead class="atn-header border-bottom border-dark bg-body-tertiary">
                        <tr>
                            <th @click="sort = 'Name'" 
                                :class="{'bg-secondary': sort == 'Name'}">
                                Name
                            </th>
                            <th @click="sort = 'Code'"
                                :class="{'bg-secondary': sort == 'Code'}">
                                Code
                            </th>
                            <th v-if="!selectedVendor"
                                @click="sort = 'Location'"
                                :class="{'bg-secondary': sort == 'Location'}">
                                Location
                            </th>
                            <th v-if="!selectedVendor">
                                Primary Category
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in filteredVendors">
                            <tr @click="updateSelectedVendor(item)"
                                class="pe-action"
                                :class="[selectedVendor && selectedVendor == item ? 'table-primary' : '']">
                                <td>
                                    {{ item.Name }}
                                </td>
                                <td>
                                    {{ item.Code }}
                                </td>
                                <td v-if="!selectedVendor">
                                    {{ item.Location }}
                                </td>
                                <td v-if="!selectedVendor">
                                    {{ getCategoryName(item.Category.Code) }}
                                </td>
                            </tr>

                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-md-8"
                 v-if="selectedVendor">

                <vendor-editor  :show="showDetails"
                                        :vendor="selectedVendor"
                                        @saved="onSave"
                                        @cancel="onCancel">
                </vendor-editor>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import Vendor from '../models/Vendor'
export default {
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    computed: {
        filteredVendors() {
            if(!this.filter && !this.sort) {
                return this.vendors;
            }

            let toReturn = [...this.allVendors];


            if(this.filter) {
                let searchTest = new RegExp(this.filter, 'i');

                toReturn = toReturn.filter(x => searchTest.test(x.Name)
                    || searchTest.test(x.Code)
                    || searchTest.test(x.Location));
            }

            if(this.sort) {
                toReturn = toReturn.sort((a, b) => {
                    if(!a[this.sort] && !b[this.sort]
                        || a[this.sort] == b[this.sort]) {
                        return 0;
                    }

                    if(a[this.sort] && !b[this.sort]) {
                        return -1;
                    }

                    if(!a[this.sort] && b[this.sort]) {
                        return 1;
                    }

                    if(a[this.sort] > b[this.sort]) {
                        return 1;
                    }

                    if(a[this.sort] < b[this.sort]) {
                        return -1;
                    }

                    return 0;
                });
            }

            return toReturn;
        },
    },

    methods: {  
        getCategoryName(code) {
            let category = this.showDetails.VendorCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        updateSelectedVendor(item) {
            this.selectedVendor = null;
            Vue.nextTick(() => this.selectedVendor = item);
        },

        showAddNew(categoryCode){
            let vendor = new Vendor();

            vendor.Id = '0';
            vendor.Show = this.copyShowShell(this.show);
            vendor.ContactInfo = new SessionContactInfo();
            vendor.Category = {
                Code: categoryCode
            };

            this.selectedVendor = vendor;
        },

        onSave() {
            this.selectedVendor = null;
            this.build();
        },

        onCancel() {
            this.selectedVendor = null;
        },

        async build() {
            this.submitting = true;
                       
            try {
                // TODO: Have the API just return the straight list of vendors. Again...
                let result = await this.tryGet(`/api/show/${this.showCode}/vendors`);
                this.vendors = result.Result;
                this.allVendors = [];

                this.showDetails.VendorCategories.forEach(c => {
                    let vendors = this.vendors[c.Code];

                    if(!vendors || vendors.length == 0) {
                        return;
                    }

                    this.allVendors.push(...vendors);
                });

            } catch {
                alert("Could not load pages. Try again later.");
            }
            
            this.submitting = false;
        },
    },

    data() {
        return {
            allVendors: [],

            vendors: {},
            selectedVendor: null,
            filter: '',
            sort: 'Code',
        }
    },

    mounted: function() {
        this.build();
    },
}
</script>