<template>
    <div class="container h-100 d-flex justify-content-center align-items-center">
        <vue-title :title="'Login'"></vue-title>
        <div class="card mx-auto mt-1"
             style="max-width: 350px; min-width: 350px">

            <div class="card-body">
                <form autocomplete="on" v-on:submit.prevent="onLogin">
                    <h5>Tractus Login</h5>

                    <div class="d-grid gap-2">

                        <div class="form-floating">
                            <input v-model="userName"
                                    :disabled="submitting" 
                                    type="email" 
                                    class="form-control"/>
                            <label>User Name</label>
                        </div>

                        <div class="form-floating">
                            <input v-model="password"
                                        :disabled="submitting" 
                                        type="password" 
                                        class="form-control" />
                            <label>Password</label>
                        </div>

                        <button type="submit" @submit.prevent 
                            class="btn btn-primary"
                            v-on:click="onLogin"
                            :disabled="submitting || !userName || !password">Sign In</button>


                        <div v-if="validateErrors && validateErrors.length > 0" class="alert alert-danger text-start" role="alert">
                                <h4 class="alert-heading">Could not log in</h4>
                                <hr>
                                <p v-for="error in validateErrors">
                                    {{error}}
                                </p>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Token from './authentication/token'

export default {
  mixins: [
      Token
  ],
  name: 'PublicPollResponse',
  data () {
    return {
      validateErrors: [],
      submitting: false,
      userName: '',
      password: ''
    }
  },

  computed: {
  },

  created: function() {
      this.userName = localStorage.getItem("userName");
  },

  methods: {
    validateForm: function() {
        this.validateErrors = [];
        
        if(!this.userName) {
            this.validateErrors.push("User name cannot be blank.");
        }

        if(!this.password) {
            this.validateErrors.push("Password cannot be blank.");
        }

        return this.validateErrors.length === 0;
    },

    async onLogin(ev) {
      this.submitting = true;

      if(!this.validateForm()) {
        this.submitting = false;
        return;
      }

      try{
        let r = await this.tryLogin(this.userName, this.password);
        if(r.Errors && r.Errors.length > 0) {
              this.validateErrors.push(r.Errors[0]);
          } else {
              this.$router.push('/shows');
          }
      }
      catch(ex){
        console.log(ex);
        this.validateErrors.push(ex);  
      }
      this.submitting = false;
    },
  }
}
</script>
<style scoped>
.form-signin {
  width: 100%;
  max-width: 450px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
