// Tractus.Data.Attendee
//  inherits from Tractus.Data.TractusEntity.
// Generated on 2019-11-12 12:53:01 PM by Elias

class Attendee {
	//public String FirstName
	FirstName = null;
	//public String LastName
	LastName = null;
	//public String DisplayName
	DisplayName = null;
	//public String SignInPin
	SignInPin = null;
	//public String PrimaryEmail
	PrimaryEmail = null;
	//public String PrimaryPhone
	PrimaryPhone = null;
	//public Boolean DoNotContact
	DoNotContact = false;
	//public Boolean PleaseContact
	PleaseContact = false;
	//public Show Show
	Show = null;
	//public Tractus.Data.Visit[] Visits
	Visits = [];
	//public AttendeeAdditionalInfo ContactInfo
	ContactInfo = null;
	//public Tractus.Data.Vendor[] VendorCanScan
	VendorCanScan = [];
	//public Tractus.Data.Category[] Categories
	Categories = [];
	//public String RoleCode
	RoleCode = null;
	//public BreakoutSchedule[] Breakouts
	Breakouts = [];
	//public Boolean ContactOptOut
	ContactOptOut = false;
	//public Media PrimaryMedia
	PrimaryMedia = null;
	//public String Id
	Id = null;
	//public String Code
	Code = null;
	//public JObject AdditionalProperties
	AdditionalProperties = null;
}
export default Attendee
