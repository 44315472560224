<template>
    <div>
        <div class="row mb-2 mt-2 me-2 p-2 shadow">
            <div class="form-group col">
                <div class="row">
                    <div class="col">
                        <div class="form-floating mt-2">
                            <select class="form-select" 
                                    @change="onChangeType"
                                    v-model="contactInfo.Type">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                <option v-for="ciType in selectTypes"
                                        :value="ciType.Type"
                                        :key="ciType.Type">
                                    {{ciType.Name}}
                                </option>
                            </select>
                            <label>Select Type...</label>
                        </div>
                    </div>
                    <div class="col"  v-if="contactInfo.Type == 'TractusLink'">
                        <resource-selector :showDetails="show" 
                                           :value.sync="contactInfo.Value"
                                           :displayText.sync="contactInfo.DisplayText"
                                           :detailText.sync="contactInfo.Details"
                                           :destinations="destinations">
                                           
                        </resource-selector>
                    </div>
                    <div class="col-auto">
                        <button type="button" @click="onDelete" class='btn btn-danger'>
                            <i class="bi bi-trash pe-1"></i>
                        </button>
                    </div>
                </div>
                <div class="form-floating mt-2">
                    <input :disabled="submitting" 
                       class="mt-1 mb-1 form-control"
                       v-model="contactInfo.DisplayText" />
                       <label>Display As</label>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-floating mt-2">
                            <textarea :disabled="submitting" 
                                    class="mt-1 mb-1 form-control"
                                    v-model="contactInfo.Details" />
                            <label>Details</label>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-floating mt-2">

                            <select v-model="contactInfo.Size"
                                    class="form-select">
                                <option selected disabled hidden :value="null">Default</option>
                                <option v-for="sizeCode in sizeCodes" 
                                        :value="sizeCode.Code">{{sizeCode.Name}}</option>
                            </select>
                            <label>Element Width</label>
                        </div>
                    </div>

                    <div class="col-auto">
                        <label class="form-check-label">
                            <input type="checkbox"
                                   class="form-check-input"
                                   v-model="contactInfo.IsHidden">
                            Hidden
                        </label>
                        <div>
                            <label class="form-check-label">
                                <input type="checkbox"
                                    class="form-check-input"
                                    v-model="contactInfo.IsLocked">
                                Locked
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="showRoleCode">
                    <div class="col">
                        <div class="form-floating mt-2">
                            <select v-model="contactInfo.RoleCode"
                                    class="form-select">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                <option v-for="roleCode in roleCodes" :value="roleCode.Code">{{roleCode.Name}}</option>
                            </select>
                            <label>Role</label>
                        </div>
                    </div>
                </div>

                <div class="form-floating mt-2" v-if="contactInfo.Type != 'Section' && contactInfo.Type != 'Downloadable' && contactInfo.Type != 'TractusLink' && isContact == true">
                    <input :disabled="submitting" 
                           class="form-control"
                           v-model="contactInfo.Value" />
                    <label>Path</label>
                </div>
                
                <file-picker ref="pickerImage" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            type="image"
                            v-if="contactInfo.Type == 'Image'"/>
                <button type="button" @click="onChooseImage"
                        v-if="contactInfo.Type == 'Image'">
                    Choose Image...
                </button>
                <file-picker ref="picker" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            v-if="contactInfo.Type == 'Downloadable'"/>
                <button type="button" @click="onChooseFile"
                        v-if="contactInfo.Type == 'Downloadable'">
                    Choose File...
                </button>  
                <a v-if="contactInfo.Value && contactInfo.Type == 'Downloadable'" 
                    :href="contactInfo.Value | absoluteAssetPath"
                    target="_blank">
                    {{contactInfo.Value}}
                </a>
                <a v-if="contactInfo.IconUrl && contactInfo.Type == 'Image'"
                    :href="contactInfo.IconUrl | absoluteAssetPath"
                    target="_blank">
                    {{contactInfo.IconUrl}}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { getApiUrl } from '../environment.hci'
import Vue from 'vue'

export default {
    
    props: [
        'contactInfo',
        'selectTypes',
        'submitting', 
        'show', 
        'isContact',
        'destinations',
    ],

    computed:{
        showRoleCode() {
            if(this.contactInfo.Type == "TractusLink" 
            && this.contactInfo.Value
            && this.contactInfo.Value.includes("tractus://Attendee")){
                return true;
            }
            return false;
        }
    },

    methods: {
        onChooseFile(){
            this.$refs.picker.openPicker();
        },

        onChooseImage(){
            this.$refs.pickerImage.openPicker();
        },

        onDelete() {
            this.$emit("delete-contact-info");
        },

        onFileSelected(fileName){
            if(this.contactInfo.Type == "Image"){
                this.contactInfo.IconUrl = fileName;
            }
            else{
                this.contactInfo.Value = fileName;
            }
            
        },

        onChangeType() {
            if(this.isContact) {
                this.contactInfo.Value = '';
            } else {
                this.contactInfo.Url = '';
            }
        },

        onUploaded(result) {
            this.contactInfo.Value = getApiUrl() + result.Result;
        },
    },

    created() {
        if(!this.contactInfo.Style){
            Vue.set(this.contactInfo,'Style',null);
        }

        if(!this.contactInfo.RoleCode){
            Vue.set(this.contactInfo,'RoleCode',null);
        }

        if(!this.contactInfo.IsHidden) {
            Vue.set(this.contactInfo, 'IsHidden', false);
        }

        if(!this.contactInfo.Size) {
            Vue.set(this.contactInfo, 'Size', null);
        }
    },

    data: function(){
        return {
            selectedPath: null,
            disableIt: true,
            showIt:false,
            roleCodes:[
                {Name: "No Role", Code: ""},
                {Name: "Speaker", Code: "Speaker"},
                {Name: "Moderator", Code: "Moderator"}
            ],

            sizeCodes: [
                {
                    Name: '1/3',
                    Code: "Third"
                },
                {
                    Name: "1/2",
                    Code: "Half"
                },
                {
                    Name: "2/3",
                    Code: "TwoThird"
                },
                {
                    Name: "Full",
                    Code: 'Full'
                }
            ]
        };
    }
}
</script>
