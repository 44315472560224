<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <div class="row" v-if="!submitting">
            <div class="col">
                <div class="d-flex mt-1">
                    <div class="form-floating flex-fill">
                        <select v-model="currentSort"
                                class="form-select">
                            <option v-for="sorttype in sortTypes" :value="sorttype.Code">
                                {{sorttype.Name}}                            
                            </option>
                        </select>
                        <label>Display Mode</label>
                    </div>
                    <button class="ms-2 btn btn-outline-primary align-self-center"
                            @click="showAddNewPushNotification">
                        New...
                    </button>
                </div>

                <div class="list-group mt-1">
                    <button class="list-group-item list-group-item-action"
                            @click="showAddNewPushNotification">
                        <i class="bi bi-plus-square"></i>
                        New Announcement
                    </button>

                    <button v-for="(announcementRequest, idx) in filteredAnnouncementRequests"
                            @click="toggleEdit(announcementRequest)"
                            class="list-group-item list-group-item-action"
                            :class="{'active': announcementRequest == currentAnnouncementRequest}">
                        <announcement-item :announcement="announcementRequest"
                                           @sendClick="sendClick"
                                           @unPublishAnnouncement="unPublishAnnouncement"
                                           @deleteAnnouncement="deleteAnnouncement">

                        </announcement-item>
                    </button>

                </div>
                <div>

                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col" v-if="currentAnnouncementRequest">
                        <push-notification-editor   v-on:saved="onSave" 
                                                    v-on:cancel="onCancel"
                                                    v-on:deleted="onDelete"
                                                    v-bind:show="showDetails"
                                                    v-bind:pushNotification="currentAnnouncementRequest"
                                                    v-bind:modeTitle="modeTitle">
                        </push-notification-editor> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.message-overflow{
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap; 
    width: 150px; 
    display: inline-block;
}
.background-image-preview {
    cursor: pointer;
    max-height: 200px;
    align-self: center;
}
</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import AnnouncementRequest from '../models/AnnouncementRequest'
import announcementItem from './list-items/announcement.item.vue'

export default {
  components: { announcementItem },
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    watch:{
        currentSort(to, from) {
            this.sortList();
        }
    },

    methods: {
        toggleEdit(announcementRequest){
            if(announcementRequest.Status == 'Published'){
                return;
            }
            this.currentAnnouncementRequest = null;

            Vue.nextTick(() => this.currentAnnouncementRequest = announcementRequest);            
        },

        capitalizeFirstLetter(input) {
            let string = input.toString();
            return string.toString().charAt(0).toUpperCase() + string.slice(1);
        },
        sortList(){
            var filtered = [];
            this.filteredAnnouncementRequests = [];

            if(this.currentSort == "default"){
                this.filteredAnnouncementRequests = this.allAnnouncementRequests;
                return;
            }
            for(var i =0; i < this.allAnnouncementRequests.length; i++){
                if(this.currentSort == 'pending'){
                    if(this.allAnnouncementRequests[i].Status == "Pending"){
                        filtered.push(this.allAnnouncementRequests[i]);
                    }
                }
            }
            this.filteredAnnouncementRequests = filtered;
        },

        async unPublishAnnouncement(announcement){
            this.submitting = true;
            //deletes from announcement cache and notification but keeps announcement
            try{
                let r = await this.tryDelete(`/api/announcement/unpublish/${this.showCode}/${announcement.Id}`);
                
                if(r.Errors && r.Errors.length > 0) {
                    console.log("error");
                    this.submitting = false;
                    return;
                }
                
                console.log("success");
                this.reloadAndClear();
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            
        },
        async deleteAnnouncement(announcement){
            //deletes from everything
            this.submitting = true;
            //deletes from announcement cache and notification but keeps announcement
            try{
                let r = await this.tryDelete(`/api/announcement/delete/${this.showCode}/${announcement.Id}`);
                    
                if(r.Errors && r.Errors.length > 0) {
                    console.log("error");
                    this.submitting = false;
                    return;
                }
                
                console.log("delete success");
                this.reloadAndClear();
            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },

        showAddNewPushNotification(){
            this.resetPushNotifications();

            this.currentAnnouncementRequest = new AnnouncementRequest();
            this.currentAnnouncementRequest.Id = "0";
            this.currentAnnouncementRequest.ShowCode = this.showCode;

            this.showEditPushNotification();
        },

        showEditPushNotification(){
            this.modeTitle = "Edit Announcement";
            if(this.currentAnnouncementRequest.Id == "0"){
                this.modeTitle = "New Announcement";
            }
            this.showSelect = false;
            this.showEdit = true;
        },
     
        resetPushNotifications(){
            this.currentAnnouncementRequest = null;
        },

        reloadAndClear(){
            this.reloadAnnouncements();
            this.resetPushNotifications();
           
        },

        onSave() {
            this.reloadAndClear();
        },

        async sendClick(announcementRequest){
            this.submitting = true;
            
            let request = this.currentAnnouncementRequest;
            if(announcementRequest){
                request = announcementRequest;
            }
            console.log(request);
            try{
                let r = await this.tryPost('/api/announcement/sendannouncement', 
                JSON.stringify(request), 
                'application/json');
                    
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                this.reloadAndClear();
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },

        onCancel() {
            this.resetPushNotifications();
        },

        onDelete() {
           this.reloadAndClear();
        },

        async reloadAnnouncements() {
            this.submitting = true;
            this.allAnnouncementRequests = [];
            try{
                let r = await this.tryGet('/api/announcement/' + this.showCode);

                if(r.Result != undefined){
                    this.allAnnouncementRequests = r.Result;
                    this.filteredAnnouncementRequests = r.Result;
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
        },
    },

    data() {
        return {
            showEdit: false,
            showSelect: false,

            modeTitle: null,

            allAnnouncementRequests: [],
            filteredAnnouncementRequests: [],
            
            currentAnnouncementRequest: null,

            sortTypes: [
                {Name: 'Default', Code: 'default'},
                {Name: 'Pending', Code: 'pending'},
            ],

            displayModes: [
                { Name: "Side Bar", Code: "SideBar" },
                { Name: "Modal", Code: "Modal" },
                { Name: "None", Code: "None" }
           ],

            currentSort: 'default',

            activeIndex: null,
        }
    },

    created: function() {
        this.reloadAnnouncements();
    }
}
</script>
