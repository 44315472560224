<template>
    <div v-if="submitting">
        <loading>
        </loading>
    </div>
    <div class="container-fluid d-flex flex-column flex-grow-1 vh-100 overflow-hidden" style="height: calc(100% - 8px) !important;" v-else>
        <div class="row flex-grow-1 overflow-hidden">
            <div class="col-md-5 h-100" style="overflow-y: scroll;">
                <h5>Poll Response</h5>
                <div class="row mt-1">
                    <div class="col-auto">
                        <button class="btn btn-primary" 
                                type="button"
                                @click="nextResponse(-sourcePoll.Responses.length)">First</button>
                        <button class="btn btn-primary" 
                                type="button"
                                @click="nextResponse(-1)">Previous</button>
                    </div>
                    <div class="col text-center fw-bold">
                        Response {{responseIndex + 1}}/{{sourcePoll.Responses.length}}
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary" 
                                type="button"
                                @click="nextResponse(1)">Next</button>
                        <button class="btn btn-primary" 
                                type="button"
                                @click="nextResponse(sourcePoll.Responses.length)">Last</button>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        <div class="list-group">
                            <div v-for="link in links"
                                    v-bind:key="`${link.EntityTypeCode}_${link.EntityCode}`"
                                    @click="selectFromLink(link)"
                                    class="list-group-item list-group-item-action">
                                <button type="button" 
                                        class="btn btn-danger"
                                        @click="deleteLink(link)"><i class="bi-x"></i></button>
                                {{getLinkText(link)}}
                            </div>
                        </div>
                    </div>
                </div>      
                <div class="row mt-1">
                    <div class="col">
                        <task-editor :showCode="this.showCode"
                                    :showDetails="show"
                                    :showList="[show]"
                                    :hideCompleted="true"
                                    :entityTypeCode="'POLLRESPONSE'"
                                    :entityCode="`${this.pollCode}_${poll.Id}`">
                        </task-editor>
                    </div>
                </div>         
                <template v-for="(question, ix) in poll.Questions">
                    <div class="row" 
                         v-bind:key="question.Code">
                        <div class="col mt-2"
                                v-if="isTextDisplayQuestion(question)">
                            <div>
                                <h5 class="form-label">{{ix + 1}}. {{question.Name}}</h5>
                                <input class="form-control" 
                                        @click="onCopyToClipboard($event, ix)"
                                        @focus="onCopyToClipboard($event, ix)"
                                        v-model="question.Response"
                                        :ref="`question${ix}`"
                                        v-if="question.QuestionType == 'SingleLineText'">
                                <textarea class="form-control" 
                                        v-model="question.Response"
                                        @click="onCopyToClipboard($event, ix)"
                                        @focus="onCopyToClipboard($event, ix)"
                                        :ref="`question${ix}`"
                                        v-else-if="question.QuestionType == 'FreeformText'" />
                            </div>  
                        </div>
                        <div class="col mt-2"
                                v-else-if="isMultipleChoiceQuestion(question)">
                            <div>
                                <h5 class="form-label">{{ix + 1}}. {{question.Name}}</h5>
                                <div v-for="r in question.Answers">
                                    <input type="radio" disabled tabindex="-1"
                                        @click="onCopyToClipboard"
                                        :checked="question.Response && question.Response.indexOf(r.Response) != -1">
                                    {{r.DisplayText}}
                                </div>
                            </div>  
                        </div>
                        <div class="col mt-2"
                                v-else-if="isLabelDisplayQuestion(question)">
                            <div class="alert"
                                    :class="`alert-primary alert-${question.LabelType}`">
                                {{question.Name}}
                            </div>
                        </div>
                        <div class="col mt-2"
                                v-else-if="isFileQuestion(question)">
                            <div>
                                <h5 class="form-label">{{ix + 1}}. {{question.Name}}</h5>
                                <input class="form-control" 
                                        @click="onCopyToClipboard($event, ix)"
                                        @focus="onCopyToClipboard($event, ix)"
                                        :ref="`question${ix}`"
                                        v-model="question.Response">
                            </div>
                        </div>
                    </div> 
                    <div v-if="ix == activeQuestionIndex"
                         class="row">
                        <div class="col">
                            <template v-if="page">
                                <div class="row" v-if="page">
                                    <div class="col-md-4"
                                        v-if="!isFileQuestion(question)">
                                        <div class="w-100 btn-group-vertical">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.pageEditor.flashProperty(question.Response, 'Name')"
                                                    type="button">
                                                Name
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.pageEditor.flashContactInfoProperty(question.Response, 'Bio')"
                                                    type="button">
                                                Bio
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.pageEditor.flashMediaProperty(question.Response, 'Uri')"
                                                    type="button">
                                                Media URL
                                            </button>
                                        </div>
                                    </div>


                                    <div class="col-md-4"
                                        v-if="isFileQuestion(question)">
                                        <div class="w-100 btn-group-vertical">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.pageEditor.flashContactInfoProperty(question.Response, 'PhotoUrl')"
                                                    type="button">
                                                Profile Photo
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.pageEditor.flashMediaProperty(question.Response, 'ThumbnailUri')"
                                                    type="button">
                                                Cover Photo
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <select class="form-select"
                                                v-model="shadowPageContactInfoIndex"
                                                ref="selectedPageContactInfo">
                                            <option :value="null">Select Contact Info...</option>
                                            <option v-for="(contactInfo, ix) in shadowPage.ContactInfo.ContactInfo" :value="ix">
                                                {{contactInfo.DisplayText}} ({{contactInfo.Type}})
                                            </option>
                                        </select>
                                        <div class="w-100 btn-group">
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.flashContactInfoItemProperty(question.Response, shadowPageContactInfoIndex, 'DisplayText')"
                                                    type="button">
                                                Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.flashContactInfoItemProperty(question.Response, shadowPageContactInfoIndex, 'Details')"
                                                    type="button">
                                                Desc.
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.flashContactInfoItemProperty(question.Response, shadowPageContactInfoIndex, 'Value')"
                                                    type="button">
                                                URL
                                            </button>
                                        </div>
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.pageEditor, shadowPageContactInfoIndex, question.Response, 'Downloadable')"
                                                    type="button">
                                                Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.pageEditor, shadowPageContactInfoIndex, question.Response, 'Website')"
                                                    type="button">
                                                Website
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.pageEditor, shadowPageContactInfoIndex, question.Response, 'Email')"
                                                    type="button">
                                                E-Mail
                                            </button>
                                        </div>                                        
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.onAddContactInfo(question.Response, 'DisplayText')"
                                                    type="button">
                                                Add Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.onAddContactInfo(question.Response, 'Value', 'Website')"
                                                    type="button">
                                                Add Link
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.onAddContactInfo(question.Response, 'Value', 'Downloadable')"
                                                    type="button">
                                                Add Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.pageEditor.onAddContactInfo(question.Response, 'Value', 'Email')"
                                                    type="button">
                                                Add Email
                                            </button>
                                        </div>                                        
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="attendee">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="w-100 btn-group mt-1"
                                            v-if="isFileQuestion(question)">
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashContactInfoProperty(question.Response, 'PhotoUrl')"
                                                    type="button">
                                                Profile Photo
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashMediaProperty(question.Response, 'ThumbnailUri')"
                                                    type="button">
                                                Cover Photo
                                            </button>
                                        </div>
                                        <div class="w-100 btn-group mt-1" v-if="!isFileQuestion(question)">
                                            <button class="btn btn-primary"
                                                    @click="intelligentFillFirstName(question)"
                                                    type="button">
                                                First (Split)
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="intelligentFillLastName(question, 0)"
                                                    type="button">
                                                Last (Split)
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="intelligentFillLastName(question, 1)"
                                                    type="button">
                                                Last (Split-2)
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashProperty(question.Response, 'DisplayName')"
                                                    type="button">
                                                Display
                                            </button>
                                        </div>                                           
                                        <div v-if="!isFileQuestion(question)"
                                             class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashProperty(question.Response, 'FirstName')"
                                                    type="button">
                                                First Name
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashProperty(question.Response, 'LastName')"
                                                    type="button">
                                                Last Name
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashProperty(question.Response, 'PrimaryEmail')"
                                                    type="button">
                                                E-Mail
                                            </button>
                                        </div>
                                        <div v-if="!isFileQuestion(question)"
                                             class="btn-group w-100 mt-1">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashContactInfoProperty(question.Response, 'Title')"
                                                    type="button">
                                                Title
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashContactInfoProperty(question.Response, 'SubTitle')"
                                                    type="button">
                                                Subtitle
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashContactInfoProperty(question.Response, 'Bio')"
                                                    type="button">
                                                Bio
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.attendeeEditor.flashMediaProperty(question.Response, 'Uri')"
                                                    type="button">
                                                Media URL
                                            </button>
                                        </div>
                                        <select class="form-select"
                                                v-model="shadowAttendeeContactInfoIndex"
                                                ref="selectedAttendeeContactInfo">
                                            <option :value="null">Select Contact Info...</option>
                                            <option v-for="(contactInfo, ix) in shadowAttendee.ContactInfo.ContactInfo" :value="ix">
                                                {{contactInfo.DisplayText}} ({{contactInfo.Type}})
                                            </option>
                                        </select>

                                        <div class="w-100 btn-group">
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashContactInfoItemProperty(question.Response, shadowAttendeeContactInfoIndex, 'DisplayText')"
                                                    type="button">
                                                Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashContactInfoItemProperty(question.Response, shadowAttendeeContactInfoIndex, 'Details')"
                                                    type="button">
                                                Desc.
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.flashContactInfoItemProperty(question.Response, shadowAttendeeContactInfoIndex, 'Value')"
                                                    type="button">
                                                URL
                                            </button>
                                        </div>
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.attendeeEditor, shadowAttendeeContactInfoIndex, question.Response, 'Downloadable')"
                                                    type="button">
                                                Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.attendeeEditor, shadowAttendeeContactInfoIndex, question.Response, 'Website')"
                                                    type="button">
                                                Website
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.attendeeEditor, shadowAttendeeContactInfoIndex, question.Response, 'Email')"
                                                    type="button">
                                                E-Mail
                                            </button>
                                        </div>  
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.onAddContactInfo(question.Response, 'DisplayText')"
                                                    type="button">
                                                Add Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.onAddContactInfo(question.Response, 'Value', 'Website')"
                                                    type="button">
                                                Add Link
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.onAddContactInfo(question.Response, 'Value', 'Downloadable')"
                                                    type="button">
                                                Add Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.attendeeEditor.onAddContactInfo(question.Response, 'Value', 'Email')"
                                                    type="button">
                                                Add Email
                                            </button>
                                        </div>    
 


                                    </div>
                                </div>
                            </template>      
                            <template v-else-if="session">
                                <div class="row" v-if="session">
                                    <div class="col-md-4"
                                        v-if="!isFileQuestion(question)">
                                        <div class="w-100 btn-group-vertical">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.sessionEditor.flashProperty(question.Response, 'Name')"
                                                    type="button">
                                                Name
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.sessionEditor.flashContactInfoProperty(question.Response, 'Bio')"
                                                    type="button">
                                                Bio
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.sessionEditor.flashMediaProperty(question.Response, 'Uri')"
                                                    type="button">
                                                Media URL
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-4"
                                        v-if="isFileQuestion(question)">
                                        <div class="w-100 btn-group-vertical mt-1">
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.sessionEditor.flashContactInfoProperty(question.Response, 'PhotoUrl')"
                                                    type="button">
                                                Profile Photo
                                            </button>
                                            <button class="btn btn-primary w-100"
                                                    @click="$refs.sessionEditor.flashMediaProperty(question.Response, 'ThumbnailUri')"
                                                    type="button">
                                                Cover Photo
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-md-8">
                                        <select class="form-select"
                                                v-model="shadowSessionContactInfoIndex"
                                                ref="selectedSessionContactInfo">
                                            <option :value="null">Select Contact Info...</option>
                                            <option v-for="(contactInfo, ix) in shadowSession.ContactInfo.ContactInfo" :value="ix">
                                                {{contactInfo.DisplayText}} ({{contactInfo.Type}})
                                            </option>
                                        </select>

                                        <div class="w-100 btn-group">
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.flashContactInfoItemProperty(question.Response, shadowSessionContactInfoIndex, 'DisplayText')"
                                                    type="button">
                                                Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.flashContactInfoItemProperty(question.Response, shadowSessionContactInfoIndex, 'Details')"
                                                    type="button">
                                                Desc.
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.flashContactInfoItemProperty(question.Response, shadowSessionContactInfoIndex, 'Value')"
                                                    type="button">
                                                URL
                                            </button>
                                        </div>
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.sessionEditor, shadowSessionContactInfoIndex, question.Response, 'Downloadable')"
                                                    type="button">
                                                Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.sessionEditor, shadowSessionContactInfoIndex, question.Response, 'Website')"
                                                    type="button">
                                                Website
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="flashContactInfoValueAndSetType($refs.sessionEditor, shadowSessionContactInfoIndex, question.Response, 'Email')"
                                                    type="button">
                                                E-Mail
                                            </button>
                                        </div>  
                                        <div class="w-100 btn-group mt-1">
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.onAddContactInfo(question.Response, 'DisplayText')"
                                                    type="button">
                                                Add Title
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.onAddContactInfo(question.Response, 'Value', 'Website')"
                                                    type="button">
                                                Add Link
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.onAddContactInfo(question.Response, 'Value', 'Downloadable')"
                                                    type="button">
                                                Add Download
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="$refs.sessionEditor.onAddContactInfo(question.Response, 'Value', 'Email')"
                                                    type="button">
                                                Add Email
                                            </button>
                                        </div>    
                                    </div>
                                </div>
                            </template>                        
                        </div>
                    </div>
                </template>      
            </div>
            <div class="col-md-7 h-100" style="overflow-y: scroll;">
                <div class="input-group">
                    <div class="d-flex me-1 align-items-center">
                        Page
                    </div>
                    <select v-model="page" class="form-control"> 
                        <!-- :label="show.VendorCategories.find(x=>x.Code == pageCategory).Name" -->
                        <optgroup v-for="(pages, pageCategory) in vendors"
                            :label="getNameForVendorCategory(pageCategory)">
                            <option v-for="page in pages" :value="page">
                                {{page.Name}}
                            </option>
                        </optgroup>
                    </select>
                    <select v-model="pageCategory" class="form-control">
                        <option :value="null">Select Category</option>
                        <option v-for="category in show.VendorCategories"
                                :value="category">
                            {{category.Name}}
                        </option>
                    </select>
                    <button type="button" 
                            class="ms-3 btn btn-primary"
                            @click="createNewPage">
                        New Page
                    </button>
                </div>
                <div class="input-group">
                    <div class="d-flex me-1 align-items-center">
                        Attendee
                    </div>
                    <select v-model="attendee" class="form-control"> 
                        <option v-for="attendee in attendees" :value="attendee">
                            {{attendee.DisplayName}} ({{attendee.Code}})
                        </option>
                    </select>
                    <button type="button" 
                            class="ms-3 btn btn-primary"
                            @click="createNewAttendee">
                        New Attendee
                    </button>
                </div>
                <div class="input-group">
                    <div class="form-check form-check-inline mt-2 mb-2"
                         v-for="category in candidateAttendeeCategories"
                         v-bind:key="category.Category.Code">
                        <input class="form-check-input" type="checkbox" :id="`a_${category.Category.Code}`" v-model="category.Checked">
                        <label class="form-check-label" :for="`a_${category.Category.Code}`">{{category.Category.Name}}</label> 
                    </div>
                </div>
                <div class="input-group">
                    <div class="d-flex me-1 align-items-center">
                        Session
                    </div>
                    <select v-model="session" class="form-control"> 
                        <optgroup v-for="(catSessions, sessionCategory) in sessions"
                                  :label="show.SessionCategories.find(x=>x.Code == sessionCategory).Name">
                            <option v-for="session in catSessions" :value="session">
                                {{session.Name}}
                            </option>
                        </optgroup>
                    </select>
                    <select v-model="sessionCategory" class="form-control">
                        <option :value="null">Select Category</option>
                        <option v-for="category in show.SessionCategories"
                                :value="category">
                            {{category.Name}}
                        </option>
                    </select>
                    <button type="button" 
                            class="ms-3 btn btn-primary"
                            @click="createNewSession">
                        New Session
                    </button>
                </div>

                <div>
                    <vendor-editor :show="show"
                                   :vendor="page"
                                   v-on:update:vendor="onUpdateVendor"
                                   @saved="onSavePage"
                                   @cancel="onCancelPage"
                                   ref="pageEditor"
                                   v-if="page">
                    </vendor-editor>
                </div>
                <div>
                    <attendee-editor :show="show"
                                     :attendee="attendee"
                                     v-on:update:attendee="onUpdateAttendee"
                                     @saved="onSaveAttendee"
                                     @cancel="onCancelAttendee"
                                     ref="attendeeEditor"
                                     v-if="attendee">
                    </attendee-editor>  
                </div>
                <div>
                    <session-editor :session="session"
                                    :show="show"
                                    v-on:update:session="onUpdateSession"
                                    v-if="session"
                                    ref="sessionEditor"
                                    v-on:cancel="onCancelSession"
                                    v-on:saved="onSaveSession">
                    </session-editor>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import Vendor from '../models/Vendor'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import ContactInfo from '../models/AttendeeAdditionalInfo'
import Attendee from '../models/Attendee'
import Session from '../models/Session'

export default {
    props: ['pollCode', 'showCode', 'responseCode', 'showDetails'],

    mixins: [
        Token,
        Common
    ],

    watch: {
        page() {
            this.shadowPage = this.page;

            if(this.shadowPage == null) {
                this.shadowPageContactInfoIndex = null;
            }
        },

        attendee() {
            this.shadowAttendee = this.attendee;
            if(this.shadowAttendee == null) {
                this.shadowAttendeeContactInfoIndex = null;
            }
        },

        session() {
            this.shadowSession = this.session;

            if(this.shadowSession == null) {
                this.shadowSessionContactInfoIndex = null;
            }
        },

        responseCode(to, from) {
            if(!this.sourcePoll) {
                return;
            }

            this.poll = this.sourcePoll.Responses.find(x => x.Id == to);
        },
    },

    methods: {
        clearEditors(){
            this.attendee = null;
            this.session = null;
            this.page = null;
        },
        getNameForVendorCategory(vendorCategoryCode){
            let vendorCategory = this.show.VendorCategories.find(x=>x.Code == vendorCategoryCode);
            if(!vendorCategory)
            {
                return null;
            }
            return vendorCategory.Name;
        },
        intelligentFillFirstName(question) {
            let firstName = question.Response.trim().split(' ')[0];

            this.$refs.attendeeEditor.flashProperty(firstName, 'FirstName')
        },

        intelligentFillLastName(question, mode) {
            if(mode == 0) {
                // Take last element in array
                let splitNames = question.Response.trim().split(' ');

                this.$refs.attendeeEditor.flashProperty(splitNames[splitNames.length - 1], 'LastName')
            } else if (mode == 1) {

                let splitNames = question.Response.trim().split(' ');

                let name = '';

                if(splitNames.length == 1) {
                    name = splitNames[0];
                } else {
                    splitNames.splice(0, 1);
                    name = splitNames.join(' ');
                }

                this.$refs.attendeeEditor.flashProperty(name, 'LastName')
            }
        },

        async deleteLink(toSave) {
            try {
                let url = `/api/pollresponselinks/${this.showCode}/${this.pollCode}/${this.poll.Id}/delete`;

                let result = await this.tryPost(url, JSON.stringify(toSave), 'application/json');

                if(result.Result) {
                    let r = result.Result;
                    let existingItem = this.links.find(x => x.EntityTypeCode == r.EntityTypeCode && x.EntityCode == r.EntityCode);

                    if(existingItem) {
                        this.links.splice(this.links.indexOf(existingItem), 1);
                    }
                }
            } catch(ex) {
                console.error(ex);
            }
        },
        selectFromLink(link) {
            this.clearEditors();

            Vue.nextTick(() => {
                if(link.EntityTypeCode == 'SESSION') {
                    for(const categoryCode in this.sessions) {
                        let session = this.sessions[categoryCode].find(x => x.Code == link.EntityCode);

                        if(session) {
                            this.session = session;
                        }
                    }

                } else if (link.EntityTypeCode == 'VENDOR') {
                    for(const categoryCode in this.vendors) {
                        let vendor = this.vendors[categoryCode].find(x => x.Code == link.EntityCode);

                        if(vendor) {
                            this.page = vendor;
                        }
                    }

                } else if (link.EntityTypeCode == 'ATTENDEE') {
                    let attendee = this.attendees.find(x => x.Code == link.EntityCode);

                    this.attendee = attendee;
                }
            });
        },

        getLinkText(link) {
            if(link.EntityTypeCode == 'SESSION') {
                for(const categoryCode in this.sessions) {
                    let session = this.sessions[categoryCode].find(x => x.Code == link.EntityCode);

                    if(session) {
                        return `${session.Name} (${moment(session.SessionStartDateTime).format('YYYY-MM-DD HH:mm')})`;
                    }
                }

                return `Unknown Session ${link.EntityCode}`;
            } else if (link.EntityTypeCode == 'VENDOR') {

                for(const categoryCode in this.vendors) {
                    let vendor = this.vendors[categoryCode].find(x => x.Code == link.EntityCode);

                    if(vendor) {
                        return `${vendor.Name} (Page)`;
                    }
                }

                return `Unknown Page ${link.EntityCode}`;

            } else if (link.EntityTypeCode == "ATTENDEE") {
                let attendee = this.attendees.find(x => x.Code == link.EntityCode);

                return attendee ? `${attendee.DisplayName} (${attendee.Code})` : `Unknown Attendee ${link.EntityCode}`;
            }
        }, 

        onSavePage(page) {
            let pageCategoryContainer = this.vendors[page.Category.Code];

            if(!pageCategoryContainer) {
                // Uh-oh. Hard refresh needed.
            }

            let pageIndex = pageCategoryContainer.indexOf(pageCategoryContainer.find(x=>x.Code == page.Code));

            if(pageIndex == -1) {
                pageCategoryContainer.push(page);
            } else {
                pageCategoryContainer.splice(pageIndex, 1, page);
            }

            this.savePollResponseLink('VENDOR', page.Code);

            this.page = null;
        },

        onCancelPage() {
            this.page = null;
        },

        onSaveAttendee(attendee) {
            
            let attendeeIndex = this.attendees.indexOf(this.attendees.find(x => x.Code == attendee.Code));

            if(attendeeIndex == -1) {
                this.attendees.push(attendee);
            } else {
                this.attendees.splice(attendeeIndex, 1, attendee);
            }

            this.savePollResponseLink('ATTENDEE', attendee.Code);

            this.attendee = null;
        },

        onCancelAttendee() {
            this.attendee = null;
        },

        onSaveSession(show, session) {
            let sessionCategoryContainer = this.sessions[session.Category.Code];

            if(!sessionCategoryContainer) {
                // Uh-oh. Hard refresh needed.
            }

            let sessionIndex = sessionCategoryContainer.indexOf(sessionCategoryContainer.find(x=>x.Code == session.Code));

            if(sessionIndex == -1) {
                sessionCategoryContainer.push(session);
            } else {
                sessionCategoryContainer.splice(sessionIndex, 1, session);
            }

            this.savePollResponseLink('SESSION', session.Code);

            this.session = null;
        },

        onCancelSession() {
            this.session = null;
        },


        isFileQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'ImageUpload'
                    || question.QuestionType == 'LargeUpload');

        },

        isMultipleChoiceQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'MultipleChoice'
                    || question.QuestionType == 'MultipleChoiceMultiSelect');
        },

        isTextDisplayQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'SingleLineText'
                    || question.QuestionType == 'FreeformText');
        },

        isLabelDisplayQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && question.QuestionType == 'TextPrompt';
        },

        isQuestionGroupTemplate(question) {
            return question.GroupTag && !question.SubGroup;
        },

        flashContactInfoValueAndSetType(editor, index, value, type) {
            editor.flashContactInfoItemProperty(type, index, 'Type');
            editor.flashContactInfoItemProperty(value, index, 'Value');
        },

        onCopyToClipboard(element, questionIndex) {
            console.log(element)
            console.log(questionIndex)

            element = element.target;
            element.select();
            element.setSelectionRange(0,99999);
            this.activeQuestionIndex = questionIndex;
        },

        onUpdateVendor(page) {
            let oldContactInfoCount = this.shadowPage.ContactInfo.ContactInfo.length;
            this.shadowPage = page;

            if(this.oldContactInfoCount != this.shadowPage.ContactInfo.ContactInfo.length) {
                this.shadowPageContactInfoIndex = this.shadowPage.ContactInfo.ContactInfo.length - 1;
            }
        },
        
        onUpdateAttendee(attendee) {
            let oldContactInfoCount = this.shadowAttendee.ContactInfo.ContactInfo.length;
            this.shadowAttendee = attendee;

            if(this.oldContactInfoCount != this.shadowAttendee.ContactInfo.ContactInfo.length) {
                this.shadowAttendeeContactInfoIndex = this.shadowAttendee.ContactInfo.ContactInfo.length - 1;
            }
        },

        onUpdateSession(session) {
            let oldContactInfoCount = this.shadowSession.ContactInfo.ContactInfo.length;
            this.shadowSession = session;

            if(this.oldContactInfoCount != this.shadowSession.ContactInfo.ContactInfo.length) {
                this.shadowSessionContactInfoIndex = this.shadowSession.ContactInfo.ContactInfo.length - 1;
            }
        },

        nextResponse(direction) {
            if(this.responseIndex + direction >= this.sourcePoll.Responses.length) {
                this.responseIndex = this.sourcePoll.Responses.length - 1;
            } else if (this.responseIndex + direction < 0) {
                this.responseIndex = 0;
            } else {
                this.responseIndex += direction;
            }

            let id = this.sourcePoll.Responses[this.responseIndex].Id;

            this.poll = this.sourcePoll.Responses.find(x => x.Id == id);

            this.retrievePollResponseLinks();
        },

        async build() {
            this.show = JSON.parse(JSON.stringify(this.showDetails));

            this.submitting = true;
            try{
                let r = await this.tryGet(`/api/polls/${this.showCode}/${this.pollCode}`);

                this.sourcePoll = r.Result;
                this.poll = this.sourcePoll.Responses.find(x => x.Id == this.responseCode);

                this.responseIndex = this.sourcePoll.Responses.indexOf(this.poll);

                if(this.poll) {
                    this.retrievePollResponseLinks();                
                }

                this.candidateAttendeeCategories = this.show.AttendeeCategories.map(x => {
                    return {
                        Checked: false,
                        Category: x
                    }
                });
            }
            catch(ex){
                console.log(ex);
            }

            try {
                let r = await this.tryGet(`/api/show/${this.show.Code}/sessions`);
                
                this.sessions = r.Result;

            } catch(ex) {
                console.error(ex);
            }

            try {
                let r = await this.tryGet(`/api/show/${this.show.Code}/vendors`);
                
                this.vendors = r.Result;

            } catch(ex) {
                console.error(ex);
            }

            try {
                let r = await this.tryGet(`/api/show/${this.show.Code}/attendees`);
                
                this.attendees = r.Result;

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
            
        },

        createNewPage() {
            this.clearEditors();

            Vue.nextTick(() => {
                let page = new Vendor();
                page.Id = '0';
                page.Show = this.copyShowShell(this.show);
                page.ContactInfo = new SessionContactInfo();
                page.Category = this.pageCategory;
                this.page = page;    
            });
            
        },

        createNewAttendee() {
            this.clearEditors();

            Vue.nextTick(() => {
                let newAttendee = new Attendee();
                newAttendee.ContactInfo = new ContactInfo();
                newAttendee.Id = "0";
                newAttendee.Code = "";
                newAttendee.Show = {
                    Code: this.showDetails.Code
                };

                let categories = this.candidateAttendeeCategories.filter(x => x.Checked).map(x => x.Category);

                newAttendee.Categories = categories;

                this.attendee = newAttendee;    
            });
            
        },

        createNewSession() {
            this.clearEditors();
            Vue.nextTick(() => {
                let newSession = new Session()
                newSession.SessionStartDateTime = new Date();
                newSession.SessionEndDateTime = new Date();

                newSession.Show = {
                    Code: this.show.Code
                };

                newSession.ContactInfo = new SessionContactInfo();
                newSession.Id = '0';
                newSession.Code = '';
                newSession.Category = this.sessionCategory;

                this.session = newSession;
            });
            
        },

        parseCommand(e) {
            let key = e.key;

            let ctrlModifier = e.ctrlKey;

            let altModifier = e.altKey;

            let shiftModifier = e.shiftKey;

            if(!ctrlModifier && !altModifier) {
                return;
            }

            if(key == 'a' && ctrlModifier && altModifier) {
                this.createNewAttendee();
                e.preventDefault();
                return true;
            } else if (key == 's' && ctrlModifier && altModifier) {
                this.createNewSession();
                e.preventDefault();
                return true;
            } else if (key == 'e' && ctrlModifier && altModifier) {
                this.createNewPage();
                e.preventDefault();
                return true;
            } else if (key == 'ArrowRight' && ctrlModifier && altModifier) {
                let movement = shiftModifier ? this.sourcePoll.Responses.length : 1;
                this.nextResponse(movement);
                e.preventDefault();
            } else if (key == 'ArrowLeft' && ctrlModifier && altModifier) {
                let movement = shiftModifier ? this.sourcePoll.Responses.length : 1;
                this.nextResponse(-movement);
                e.preventDefault();
            } else if (key == 'ArrowDown' && ctrlModifier && altModifier) {
                if(this.poll.Questions.length <= 1) {
                    return;
                }
                               
                while(true) {

                    this.activeQuestionIndex++;

                    if(this.activeQuestionIndex >= this.poll.Questions.length) {
                        this.activeQuestionIndex = 0;
                    }

                    let control = this.$refs[`question${this.activeQuestionIndex}`];

                    if(control && control.length > 0) {
                        control[0].focus();
                        break;
                    }
                }

                e.preventDefault();
            } else if (key == 'ArrowUp' && ctrlModifier && altModifier) {
                if(this.poll.Questions.length <= 1) {
                    return;
                }
                               
                while(true) {

                    this.activeQuestionIndex--;

                    if(this.activeQuestionIndex < 0) {
                        this.activeQuestionIndex = this.poll.Questions.length - 1;
                    }

                    let control = this.$refs[`question${this.activeQuestionIndex}`];

                    if(control && control.length > 0) {
                        control[0].focus();
                        break;
                    }
                }

                e.preventDefault();
            }
        },

        async retrievePollResponseLinks() {
            this.links = [];

            try {
                let url = `/api/pollresponselinks/${this.showCode}/${this.pollCode}/${this.poll.Id}`;
                let result = await this.tryGet(url);

                this.links = result.Result || [];
            } catch(ex) {
                console.error(ex);
            }
        },

        async savePollResponseLink(entityTypeCode, entityCode) {
            let toSave = {
                EntityTypeCode: entityTypeCode,
                EntityCode: entityCode,

            };

            try {
                let url = `/api/pollresponselinks/${this.showCode}/${this.pollCode}/${this.poll.Id}`;

                let result = await this.tryPost(url, JSON.stringify(toSave), 'application/json');

                if(result.Result) {
                    let r = result.Result;
                    let existingItem = this.links.find(x => x.EntityTypeCode == r.EntityTypeCode && x.EntityCode == r.EntityCode);

                    if(!existingItem) {
                        this.links.push(r);
                    } else {
                        this.links.splice(this.links.indexOf(existingItem), 1, r);
                    }
                }
            } catch(ex) {
                console.error(ex);
            }
        }
    },

    data(){ 
        return{
            sessions:[],
            attendees: [],
            vendors: [],

            sourcePoll: null,   
            poll: null,

            // We'll keep a copy of the show so we don't always have
            // to go back to the console to grab an updated copy.
            show: null,
            
            // These are the "selected" copies.
            page: null,
            pageCategory: null,
            attendee: null,
            session: null,
            sessionCategory: null,

            // These are the "shadow update" copies.
            // We use these to keep track of the user's current edits.
            // On save or cancel, we null these out.
            shadowPage: null,
            shadowPageContactInfoIndex: null,

            shadowAttendee: null,
            shadowAttendeeContactInfoIndex: null,

            shadowSession: null,
            shadowSessionContactInfoIndex: null,

            responseIndex: null,
            activeQuestionIndex: null,

            links: [],
            candidateAttendeeCategories: [],
        }
    },

    destroyed() {
        window.removeEventListener('keydown', this.parseCommand)
    },

    created(){
        window.addEventListener('keydown', this.parseCommand);
        this.build();
    }
}
</script>