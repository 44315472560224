<template>
    <form class="form-horizontal">
        <div>
            <label>
                {{modeTitle}}
            </label>
        </div>
        <div v-if="editAnnouncement.Id != 0">
            <label>
                {{editAnnouncement.Status}} <span v-if="editAnnouncement.ScheduledDateTime"> ({{editAnnouncement.ScheduledDateTime | moment('dddd, MMMM Do YYYY h:mm a')}})</span>
            </label>
        </div>

        <div class="d-flex justify-content-between mt-1">
            <button type="button"
                    class="btn btn-secondary"
                    @click="onCancel">
                Cancel
            </button>

            <button type="button"
                    class="btn btn-outline-danger"
                    @click="onDelete">
                Delete
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-primary"
                        @click="onSave">
                    Save
                </button>
            </div>
        </div>

        <div class="form-floating mt-2">
            <input :disabled="submitting"
                    class="form-control"
                    v-model='editAnnouncement.Subject'>

            <label>
                Title ({{(editAnnouncement.Subject || '').length}})
            </label>
        </div>
        <div class="form-floating mt-2">
            <textarea :disabled="submitting" 
                    class="form-control"
                    v-model="editAnnouncement.Message">
            </textarea>
            
            <label>
                Message ({{(editAnnouncement.Message || '').length}})            
            </label>
        </div>
        <div class="form-floating mt-2">
            
            <select class="form-select" v-model="editAnnouncement.DisplayMode">
                <option v-for="mode in displayMode" :value="mode.Code">{{mode.Name}}</option>
            </select>
            <label>
                Display Mode
            </label>
        </div>
        <div class="form-group row mt-2">
            <div class="col-sm-6">
                <div class="form-check">
                    <input :disabled="submitting"
                            class="form-check-input" 
                            type="checkbox"
                            v-model="editAnnouncement.IsPinned">
                    <label>
                        Is Pinned
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group mt-2">
                <resource-selector :showDetails="show" 
                                :elementTypeCode.sync="editAnnouncement.DestinationType"
                                :code.sync="editAnnouncement.DestinationCode"
                                :destinations="tractusLinkDestinations"
                                v-if="tractusLinkDestinations"
                                :placeholder="'No Destination'">
                </resource-selector>
                <loading v-else />
                
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">
                Background Image
            </label>
            <div class="col-sm-10" @click="onChoose" v-if='editAnnouncement.PrimaryMediaUrl'>
                <!-- <button type="button" @click="onChoose">Choose Image</button> -->
                 
                     <!-- class="img-fluid" -->
                    <img :src="editAnnouncement.PrimaryMediaUrl"
                            class="background-image-preview"
                            :acceptFileType="'image/*'"
                            v-if="editAnnouncement.PrimaryMediaUrl">
                
            </div>
            <div v-else class="no-image-preview" @click="onChoose">
                No Image Selected
            </div>
        </div>
        <file-picker ref="picker" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="editAnnouncement.PrimaryMediaUrl"/>
        <div class="form-group">
                <attendee-picker :showDetails="show"
                                 :attendeesSelected="editAnnouncement.Recipients"
                                 ref="attendeePicker">

                </attendee-picker>
        </div>
        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>
    </form>
</template>
<style scoped>
.no-image-preview {
    cursor: pointer;
    background: #ccc;
    display: flex;
    height: 150px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid #999;
}
.background-image-preview {
    cursor: pointer;
    max-height: 200px;
    align-self: center;
}
</style>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default {
    props: ['show', 'pushNotification','modeTitle'],
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            editAnnouncement: null,

            displayMode: [
                { Name: "Side Bar", Code: "SideBar" },
                { Name: "Modal", Code: "Modal" },
                { Name: "None", Code: "None" }
           ],
        }
    },

    created: function() {
        let toEdit = this.cloneObject(this.pushNotification);
        this.editAnnouncement = toEdit;
        this.build();
    },

    methods: {
        async build() {
            await this.getTractusLinkDestinationsAsync(this.show.Code);
        },

        onChoose(){
            this.$refs.picker.openPicker();
        },
        onFileSelected(fileName){
            this.editAnnouncement.PrimaryMediaUrl = fileName;
        },
        validateForm: function() {
            this.validateErrors = [];

            if(this.$refs.attendeePicker.getSelectedAttendees().length == 0){
                this.validateErrors.push("Please select someone to send announcement to");
            }

            if(!this.editAnnouncement.Subject){
                this.validateErrors.push("Title cannot be blank");
            }
            
            var whitespc = /^ *$/;
            if(this.editAnnouncement.Subject && this.editAnnouncement.Subject.match(whitespc)){
                this.validateErrors.push("Title cannot be only spaces.");
            }
            
            if(!this.editAnnouncement.Message){
                this.validateErrors.push("Message cannot be blank");
            }

            if(this.editAnnouncement.Message && this.editAnnouncement.Message.match(whitespc)){
                this.validateErrors.push("Message cannot be only spaces.");
            }

            return this.validateErrors.length === 0;
        },
        onCancel(){
            this.$emit('cancel');
        },
        async onDelete(){
            this.submitting = true;
            console.log(this.editAnnouncement);
            
            try{
                let r = await this.tryDelete(`/api/announcement/delete/${this.show.Code}/${this.editAnnouncement.Id}`);
                    
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else {
                    this.$emit('deleted');
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            
        },
        async onSave() {
            this.submitting = true;

            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            var recipientList = [];

            var recipients = this.$refs.attendeePicker.getSelectedAttendees();

            console.log(recipients);
            for(var i = 0; i < recipients.length; i++){
                var recipientCandidate = recipients[i];
                recipientList.push(recipientCandidate.Code);    
            }

            this.editAnnouncement.ShowCode = this.show.Code;

            this.editAnnouncement.Recipients = recipientList;

            console.log(this.editAnnouncement);

            try{
                let r = await this.tryPost('/api/announcement/update', 
                JSON.stringify(this.editAnnouncement), 
                'application/json');
                    
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else {
                    this.$emit('saved', this.editAnnouncement.Subject);
                }
            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },
    }
}
</script>
