// Tractus.Data.Vendor
//  inherits from Tractus.Data.AgendaItemBase.
// Generated on 2019-11-12 12:53:01 PM by Elias

class Vendor {
	//public String DiscussionCode
	DiscussionCode = null;
	//public String Name
	Name = null;
	//public String Location
	Location = null;
	//public Show Show
	Show = null;
	//public VendorAdditionalInfo ContactInfo
	ContactInfo = null;
	//public AgendaItemCategory Category
	Category = null;
	//public Media PrimaryMedia
	PrimaryMedia = null;
	//public String Id
	Id = null;
	//public String Code
	Code = null;
	//public JObject AdditionalProperties
	AdditionalProperties = null;
    DiscussionWebinarStatus = null;
    
}
export default Vendor
