import { getApiUrl } from './environment.hci'
import Toastify from 'toastify-js'

export default {
    data() {
        return {
            submitting: false,
            error: null,
            validateErrors: [],
            tractusLinkDestinations: null
        }
    },

    methods: {
        showToast(message) {
            Toastify({
                text: message,
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
            );
        },

        createUniqueCode() {
            let guid = this.uuidv4().replace(/-/g, '').toUpperCase();          
            return guid;
        },
             

        getApiUrl() {
            return getApiUrl();
        },

        cloneObject(source) {
            if(source) {
                return JSON.parse(JSON.stringify(source));
            }

            return null;
        },

        copyShowShell(show) {
            if(show && show.Id && show.Code && show.Name) {
                return {
                    Id: show.Id,
                    Code: show.Code,
                    Name: show.Name
                };
            }

            return null;
        },

        async getTractusLinkDestinationsAsync(showCode) {
            try {
                let r = await this.tryGet(`/api/resources/${showCode}`);
                this.tractusLinkDestinations = r.Result;

            }
            catch (ex) {
                console.log(ex);
            }
        }
    }
}