<template>
    <div v-if="!loadingCompanyDetails" class="container-fluid">
        <vue-title :title="'Integrations'"></vue-title>

        <h1>{{details.Name}}</h1>
        <hr/>
        <h5>Your Company Details</h5>

        <div class="row">
            <div class="col-auto">
                Company Name
            </div>
            <div class="col">
                <input v-model="editDetails.Name" class="form-control">
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-auto">
                Logo
                <button type="button" @click="onChooseLogo">
                    Choose File...
                </button>   
            </div>
            <div class="col">
                <img :src="editDetails.LogoUrl" class="img-logo-picker">
                <file-picker ref="eventThumbnailPicker" 
                            @fileSelected="onLogoSelected" 
                            :showCode="editDetails.Code + '_Logos'"
                            type="image"
                            :imagePath="editDetails.LogoUrl"/>
                        
            </div>
        </div>

        <div v-if="errors && errors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                Oops! It looks like something happened. Please double-check and try again.
                <ul>
                    <li v-for="error in errors">
                        {{ error }}
                    </li>
                </ul>
        </div>

    </div>
    <loading v-else>
    </loading>
</template>
<style scoped>
.img-logo-picker {
    height: auto;
    width: 300px;
}
</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { 
    getApiUrl
} from './environment.hci'

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            loadingCompanyDetails: false,
            details: null,
            editDetails: null,
            errors: [],
        }
    },

    methods: {
        copyUrl(elementId) {
            document.getElementById(elementId).select();
            document.getElementById(elementId).setSelectionRange(0,99999);
            document.execCommand("copy");

        },

        onChooseLogo(){
            // console.log(this.show);
            this.$refs.eventThumbnailPicker.openPicker();
        },

        onLogoSelected(fileName){
            this.editDetails.LogoUrl = fileName;
        },
        
        async onSaveDetails() {
            this.loadingCompanyDetails = true;
            try{
                let r = await this.tryPost(`/api/admin/updatecompanydetails`,JSON.stringify(this.editDetails), 'application/json');
                    
                if(r.Errors.length > 0){
                    this.errors = r.Errors;
                    this.loadingCompanyDetails = false;
                    return;
                }
                    
                this.loadCompanyDetails();
            }
            catch(ex){
                console.log(ex);
            }
            
            this.loadingCompanyDetails = false;
        },

        async loadCompanyDetails() {
            this.loadingCompanyDetails = true;

            try{
                let r = await this.tryGet(`/api/admin/getmycompanydetails`)

                this.details = r.Result;
                this.editDetails = JSON.parse(JSON.stringify(r.Result));
            }
            catch(err){
                console.log(err);
            }

            this.loadingCompanyDetails = false;
        }
    },

    created() {
        this.loadCompanyDetails();
        // this.loadEventCategories();
    },



}
</script>