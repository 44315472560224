<template>
    <div class="container">
        <form enctype="multipart/form-data" class="overflow-hidden" novalidate>
            <input type="file" 
                   :name="uploadFieldName" 
                   multiple
                   :disabled="isSaving"
                   @change=" onChange($event.target.name, $event.target.files,$event.target.files.length)"
                   :accept="acceptFileType" 
                   >
            <div class="dropbox border" 
                :class="{'bg-secondary' : isTarget}"
                @dragenter.prevent="onDragEnter"
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
                @drop.prevent="onDrop">
                
            </div>
            <p v-if="isSaving">
                Uploading...
            </p>
        </form>
    </div>
</template>
<script>
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
import Token from './authentication/token'

export default {
    props: ['assetType', 'acceptFileType', 'showCode'],
    mixins: [
        Token
    ],
    
    data() {
        return {
            currentStatus: STATUS_INITIAL,
            uploadedFiles: [],
            uploadFieldName: 'uploads',
            isTarget: false
        }
    },

    computed: {
        isInitial() {
           return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },

    methods: {
        onDragEnter(){
            this.isTarget = true;
        },
        onDragOver(){
            this.isTarget = true;
        },
        onDragLeave(){
            this.isTarget = false;
        },
        onDrop(e){
            this.isTarget = false;
            let files = [...e.dataTransfer.files];

            this.filesChange("uploads",files);
        },
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },

        async save(formData) {
            // upload data to the server

            this.currentStatus = STATUS_SAVING;
            if(!this.assetType){
                this.assetType = "";
            }
            try{
                let r = await this.tryPost('/api/assets/?contentPath=' + this.assetType + '&showCode=' + this.showCode, formData);
                this.$emit('uploadSuccess', r);
                this.uploadedFiles = [].concat(r);
                this.currentStatus = STATUS_SUCCESS;
                this.reset();
            }
            catch(ex){
                console.log(ex);
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
            }
      },
    
      onChange(fieldName, fileList, fileLength){
            this.filesChange(fieldName, fileList); 
            this.fileCount = fileLength;
      },

      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();
        if (!fileList.length) return;
        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });
        // save it
        this.save(formData);
      }
    },
    mounted() {
        this.reset();
    },
}
</script>
