<template>
    <loading v-if="submitting || !editDiscussionArea">

    </loading>
    <form class="form-horizontal" v-else>

        <div class="d-flex justify-content-between mt-1">
            <button type="button"
                    class="btn btn-secondary"
                    @click="onCancel">
                Cancel
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-outline-danger"
                        @click="deleteChat">
                    Clear Chat
                </button>
                <button type="button"
                        class="btn btn-outline-danger"
                        @click="deleteDiscussion">
                    Delete
                </button>
            </div>
            <div class="btn-group">
                <button type="button"
                        class="btn btn-primary"
                        @click="onSave">
                    Save
                </button>
            </div>
        </div>

        <div class="form-floating mt-2">
            <input :disabled="submitting"
                    class="form-control"
                    v-model='editDiscussionArea.Name' />
            <label>Name</label>
        </div>
        <div class="form-floating mt-2">
            <textarea :disabled="submitting" 
                    class="form-control" 
                    v-model="editDiscussionArea.Description">
            </textarea>
            <label>Description</label>
        </div>
        <div class="form-floating mt-2">
            <select class="form-select" v-model="editDiscussionArea.TypeCode">
                <option selected disabled hidden :value="null">(Please Select...)</option>
                <option v-for="qt in typeCodes" :value="qt.Code">
                    {{qt.Name}} 
                </option>
            </select>
            <label>Type</label>
        </div>
        <div class="row form-group">
            <div class="col">
                <!-- <small>Discussion Image</small> -->
                <img v-if="editDiscussionArea.PhotoUrl" 
                        @click="onChooseImage" 
                        :src="editDiscussionArea.PhotoUrl" 
                        class="img-fluid"
                        style="max-height: 96px">
                <div v-else class="no-image-preview" 
                        @click="onChooseImage">
                    No Image Selected
                </div>
                <file-picker :ref="`discussionPicker`" 
                            @fileSelected="onFileSelected($event)" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="editDiscussionArea.PhotoUrl"/>
                <button type="button" @click="onChooseImage">
                    Choose File...
                </button>  
                <button type="button" @click="editDiscussionArea.PhotoUrl = null">
                    Clear
                </button>
            </div>
        </div>
        <div class="row mt-2" >
            <div class="col-sm-4">
                <div class="form-check">
                    <input  class="form-check-input" 
                            type="checkbox" 
                            v-model="editDiscussionArea.AllowAnonymous">
                    <label :class="{'fw-bold': editDiscussionArea.AllowAnonymous}" 
                            class="form-check-label">
                            Allow Anonymous
                    </label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.AllowPost">
                    <label :class="{'fw-bold': editDiscussionArea.AllowPost}" 
                            class="form-check-label">
                            Open
                    </label>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.InviteOnly">
                    <label :class="{'fw-bold': editDiscussionArea.InviteOnly}" 
                            class=" form-check-label">
                            Hidden
                    </label>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.NotifyParticipants">
                    <label :class="{'fw-bold': editDiscussionArea.NotifyParticipants}" 
                            class="form-check-label">
                            Send Push
                    </label>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.AllowCall">
                    <label :class="{'fw-bold': editDiscussionArea.AllowCall}" 
                            class="form-check-label">
                            Allow Calls
                    </label>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="form-check">
                    <input  class=" form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.MuteOnJoin">
                    <label :class="{'fw-bold': editDiscussionArea.MuteOnJoin}" 
                            class="form-check-label">
                            Mute on Join
                    </label>
                </div>
            </div>
            <div class="col-sm-4 ">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.WebinarFormat">
                    <label :class="{'fw-bold': editDiscussionArea.WebinarFormat}" 
                            class="form-check-label">
                            Webinar Room
                    </label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-check">
                    <input  class="form-check-input" 
                        type="checkbox" 
                        v-model="editDiscussionArea.AllowTypingIndicator">
                    <label :class="{'fw-bold': editDiscussionArea.AllowTypingIndicator}" 
                            class="form-check-label">
                            Send Typing Notice
                    </label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input"
                               type="checkbox"
                               v-model="editDiscussionArea.IsGlobalChat">
                        Global Chat
                    </label>
                </div>
            </div>
            <div class="col-sm-4">
                <input v-model.number="editDiscussionArea.DelayBetweenMessageSec" 
                       type="number">
                <label>
                    Min Seconds Between Posts
                </label>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col">
                    <div class="form-floating mt-2">
                        <select v-model="selectedCategory"
                                class="form-control">
                            <option :value="null">(Please Select...)</option>
                            <option v-for="c in show.AttendeeCategories"
                                    v-bind:key="c.Code"
                                    :value="c">
                                {{c.Name}}
                            </option>
                        </select>
                        <label>Visibility Controls (No Categories = Show to All)</label>
                    </div>
                </div>
                <div class="col-auto mt-2">
                    <button type="button" 
                            class="btn btn-primary"
                            @click="addCategory">+</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="list-group">
                        <div v-for="c in editDiscussionArea.VisibleToCategories"
                             v-bind:key="c">
                            <button class="btn btn-danger" 
                                    type="button"
                                    @click="removeCategory(c)"><i class="bi-x"></i></button>
                            {{show.AttendeeCategories.find(x => x.Code == c).Name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="row">
                <div class="col">
                    <div class="form-floating mt-2">
                        <select v-model="selectedModerator"
                                class="form-control">
                            <option :value="null">(Please Select...)</option>
                            <option v-for="attendee in attendees"
                                    v-bind:key="attendee.Code"
                                    :value="attendee">
                                {{attendee.DisplayName}} ({{attendee.Code}})
                            </option>
                        </select>
                        <label>Moderators</label>
                    </div>
                    
                </div>
                <div class="col-auto">
                    <button type="button" 
                            class="btn btn-primary"
                            @click="addModerator">+</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="list-group">
                        <div v-for="(role, code) in editDiscussionArea.ParticipantRoles"
                             v-bind:key="code">
                            <button class="btn btn-danger" 
                                    type="button"
                                    @click="removeModerator(code)"><i class="bi-x"></i></button>
                            {{findMember(code)}} ({{code}})
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col">
                <div class="list-group-item list-group-item-action"
                     v-on:click="onCancel">
                    <div class="d-flex">
                        <i class="fa pe-1"></i>
                        <h6 class="mb-0">
                            Cancel
                        </h6>
                    </div>         
                </div>
            </div>
            <div class="col">
                <div class="list-group-item list-group-item-action"
                     v-on:click="onSave">
                    <div class="d-flex">
                        <i class="fa pe-1"></i>
                        <h6 class="mb-0">
                            Save
                        </h6>
                    </div>         
                </div>
            </div>
        </div>
        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>
    </form>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default {
    props: ['discussionArea', 'show'],
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            editDiscussionArea: null,
            typeCodes: [
                {
                    Code: "LiveQa",
                    Name: "Live Q&A"
                },
                {
                    Code: "GroupChat",
                    Name: "Group Chat"
                }
            ],
            selectedModerator: null,
            selectedCategory: null,
            attendeeSearch: '',
            attendees: [],
        }
    },

    created: function() {
        this.editDiscussionArea = this.discussionArea;
        if(!this.editDiscussionArea) {
            return;
        }
        
        if(!this.editDiscussionArea.MaxCallers) {
            Vue.set(this.editDiscussionArea, 'MaxCallers', 50);
        }

        if(!this.editDiscussionArea.ParticipantRoles) {
            Vue.set(this.editDiscussionArea, 'ParticipantRoles', {});
        }

        if(!this.editDiscussionArea.VisibleToCategories) {
            Vue.set(this.editDiscussionArea, 'VisibleToCategories', []);
        }

        if(!this.editDiscussionArea.MuteOnJoin) {
            Vue.set(this.editDiscussionArea, 'MuteOnJoin', false);
        }

        if(!this.editDiscussionArea.WebinarFormat) {
            Vue.set(this.editDiscussionArea, 'WebinarFormat', false);
        }

        if(!this.editDiscussionArea.AllowTypingIndicator) {
            Vue.set(this.editDiscussionArea, 'AllowTypingIndicator', false);
        }

        if(!this.editDiscussionArea.IsGlobalChat) {
            Vue.set(this.editDiscussionArea, 'IsGlobalChat', false);
        }

        if(!this.editDiscussionArea.DelayBetweenMessageSec) {
            Vue.set(this.editDiscussionArea, 'DelayBetweenMessageSec', 0);
        }

        this.getAttendeeList();
    },

    methods: {
        findMember(code) {
            let attendees = this.attendees || [];

            let attendee = attendees.find(x => x.Code == code);

            if(!attendee) {
                return "Not Found";
            }

            return attendee.DisplayName;
        },

        async deleteChat(){
            this.submitting = true;

             if(window.confirm("Are you sure you want to delete all posts? This is not reversable.")){
                 try{
                   let r = await this.tryPost(`/api/discussion/clear/${this.show.Code}/${this.editDiscussionArea.Code}`,null);
                    if(r.Errors.length !== 0) {
                        return;
                    }
                    
                    this.showEdit = false;
                 }
                 catch(ex){
                     console.log(ex);
                 }
            }

            this.submitting = false;
            
        },
        async deleteDiscussion(){
            this.submitting = true;
             if(window.confirm("Are you sure you want to permanently delete this chat? This is not reversable.")){
                 try{
                   let r = await this.tryDelete(`/api/discussion/${this.show.Code}/${this.editDiscussionArea.Code}`);
                    if(r.Errors.length !== 0) {
                        return;
                    }
                    this.reload();
                    this.showEdit = false;
                 }
                 catch(ex){
                     console.log(ex);
                 }
            }

            this.submitting = false;
            this.$emit("deleted");
        },

        async getAttendeeList() {
            this.submitting = true;

            try {
                let result = await this.tryGet(`/api/show/${this.show.Code}/attendees`);
                this.attendees = result.Result;

            } catch(ex) {
                console.error(ex);
            }
            
            this.submitting = false;
        },

        addCategory() {
            if(this.editDiscussionArea.VisibleToCategories.find(x => x == this.selectedCategory.Code)) {
                return;
            }
            this.editDiscussionArea.VisibleToCategories.push(this.selectedCategory.Code);
        },

        removeCategory(code) {
            let index = this.editDiscussionArea.VisibleToCategories.indexOf(code);
            if(index == -1) {
                return;
            } 

            this.editDiscussionArea.VisibleToCategories.splice(index, 1);
        },

        addModerator() {
            Vue.set(this.editDiscussionArea.ParticipantRoles, this.selectedModerator.Code, 'Administrator');
        },

        removeModerator(code) {
            let newRoles = this.editDiscussionArea.ParticipantRoles;
            delete newRoles[code];

            newRoles = Object.assign({}, newRoles);

            Vue.set(this.editDiscussionArea, 'ParticipantRoles', newRoles);
        },

        onFileSelected(fileName){
            // this.editDiscussionArea.PhotoUrl = fileName;
            Vue.set(this.editDiscussionArea,"PhotoUrl",fileName);
            console.log(this.editDiscussionArea.PhotoUrl);
        },
        onChooseImage(){
            this.$refs[`discussionPicker`].openPicker();
        },
        validateForm: function() {
            this.validateErrors = [];
            return this.validateErrors.length === 0;
        },
        
        async onSave() {
            this.submitting = true;
            
            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }   
 
            try {
                let r = await this.tryPost('/api/discussion', JSON.stringify(this.editDiscussionArea), 'application/json');
                this.submitting = false;
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else {
                    this.$emit('saved', this.poll);
                }
            } catch(ex) {
                console.error(ex);
                this.submitting = false;
                this.validateErrors.push('Could not connect to server.');
            }
        },
        onCancel(){
            this.$emit('cancel');
        },
    }

}
</script>
