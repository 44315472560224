<template>
    <div>
        <div class="modal fade" 
            id="deleteVendorCategoryWarn"
             ref="deleteVendorCategoryWarn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            Delete Vendor Category
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body" v-if="editVendorCategory">
                        <p>Are you sure you want to delete {{editVendorCategory.Name}}? This will delete all vendors linked to it and can't be undone.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                @click="onDelete">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h4>
                <button type="button" class="btn btn-primary" @click="save">
                    Save
                </button>
                Vendor Categories
            </h4>

            <div class="row" v-if="validateErrors && validateErrors.length > 0">
                <div class="mt-1 alert alert-danger text-start" role="alert">
                        Oops! It looks like something happened. Please double-check and try again.
                        <ul>
                            <li v-for="error in validateErrors">
                                {{ error }}
                            </li>
                        </ul>
                </div>
            </div>

            <vue-slicklist class="list-group"
                           :useDragHandle="true" 
                           v-if="editVendorCategories"
                           v-model="editVendorCategories" lockAxis="y">
                <div class="list-group-item" @click="addCategory">
                    Add...
                </div>
            
                <vue-slickitem class="list-group-item" 
                               v-for="(vendorCategory, i) in editVendorCategories" 
                               :key="i" 
                               :index="i">
                    <form>
                        <div class="row mb-2">
                            <div class="col-auto">
                                <i class="col-auto bi-grip-vertical" v-handle></i>
                            </div>
                            <div class="col">
                                <input placeholder="Page Category Name" 
                                       v-model="vendorCategory.Name" 
                                       class="form-control">                            
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-auto">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="checkbox" 
                                            v-model="vendorCategory.IsHidden"
                                            class="form-check-input">
                                        Tag Property
                                    </label>
                                </div>

                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input :disabled="submitting" 
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="vendorCategory.AlwaysShow" />
                                        Always Visible
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-floating">

                                    <input placeholder="Category Code"
                                           class="form-control"
                                           v-model="vendorCategory.Code" />
                                    <label>
                                        Category Code
                                    </label>

                                </div>
                            </div>

                            <div class="col-auto d-flex align-items-center">
                                <button class="btn btn-danger"
                                        type="button"
                                        @click="editVendorCategory = vendorCategory;"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteVendorCategoryWarn">
                                    <i class="bi bi-trash-fill"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label class="me-2">Visible To</label>
                                <template v-for="ciType in show.AttendeeCategories">
                                    <button type="button"
                                            class="badge rounded-pill bg-light text-dark border-0"
                                            v-if="!vendorCategory.VisibleToAttendeeCategories.find(x => x.Code == ciType.Code)"
                                            :key="ciType.Code"
                                            @click="addAttendeeCategoryToVendorCategory(vendorCategory, ciType)">
                                        {{ciType.Name}}
                                    </button>
                                    <button type="button"
                                            class="badge rounded-pill bg-primary text-light border-0"
                                            v-else
                                            :key="ciType.Code"
                                            @click="removeAttendeeCategoryFromVendorCategory(vendorCategory, ciType)">
                                        {{ciType.Name}}
                                    </button>
                                </template>
                            </div>
                            <div class="col">
                                <label class="me-2">Scheduled Session Categories</label>
                                <template v-for="ciType in show.SessionCategories">
                                    <button type="button"
                                            class="badge rounded-pill bg-light text-dark border-0"
                                            v-if="!vendorCategory.AddSessionsToCategoryCodes.find(x => x == ciType.Code)"
                                            :key="ciType.Code"
                                            @click="addSessionCategoryToVendor(vendorCategory, ciType)">
                                        {{ciType.Name}}
                                    </button>
                                    <button type="button"
                                            class="badge rounded-pill bg-primary text-light border-0"
                                            v-else
                                            :key="ciType.Code"
                                            @click="removeSessionCategoryFromVendor(vendorCategory, ciType)">
                                        {{ciType.Name}}
                                    </button>
                                </template>

                            </div>
                        </div>
                    </form>
                </vue-slickitem>
                <div class="list-group-item" @click="addCategory">
                        Add...
                </div>
            </vue-slicklist>
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default{
    props: ['showDetails'],
    mixins: [
        Token, 
        Common
    ],

    data() {

        return {
            show: null,
            candidateAttendeeCategoryForVendorCategory: null,
            editVendorCategories: null,

            editVendorCategory: null,
            editShow:null,
            
            validCode: true,
            validateErrors: [],
        }
    },

    created: function(){
        this.show = this.showDetails;
        this.editVendorCategories = this.show.VendorCategories;

        for(let i = 0; i < this.editVendorCategories.length; i++) {
            let category = this.editVendorCategories[i];

            if(!category.AddSessionsToCategoryCodes) {
                Vue.set(category, 'AddSessionsToCategoryCodes', []);
            }
        }
    },

    methods:{
        removeAttendeeCategoryFromVendorCategory(vendorCategory, category) {
            vendorCategory.VisibleToAttendeeCategories.splice(vendorCategory.VisibleToAttendeeCategories.indexOf(category), 1);
        },
        addAttendeeCategoryToVendorCategory(vendorCategory, category) {
            // TODO: Rigorous checks to make sure we don't duplicate push
            if(!vendorCategory.VisibleToAttendeeCategories){
                vendorCategory.VisibleToAttendeeCategories = [];
            }
            vendorCategory.VisibleToAttendeeCategories.push(category);
        },

        addSessionCategoryToVendor(vendorCategory, category) {
            if(vendorCategory.AddSessionsToCategoryCodes.find(x => x == category.Code)) {
                return;
            }

            vendorCategory.AddSessionsToCategoryCodes.push(category.Code);
        },

        removeSessionCategoryFromVendor(vendorCategory, category) {
            
            let categoryFound = vendorCategory.AddSessionsToCategoryCodes.find(x => x == category.Code);

            let index = vendorCategory.AddSessionsToCategoryCodes.indexOf(categoryFound);

            if(index == -1) {
                return;
            }

            vendorCategory.AddSessionsToCategoryCodes.splice(index, 1);
        },


        validateForm: function() {
            this.validateErrors = [];

            for(var vendorCategory of this.editVendorCategories){
                if(vendorCategory.Name == null || vendorCategory.Name.trim() == ""){
                    this.validateErrors.push("Category Name cannot be blank.");
                }
                if(vendorCategory.VisibleToAttendeeCategories.length === 0){
                    this.validateErrors.push("Visible To... must have an attendee category assigned to it");
                }
            }
           
            return this.validateErrors.length === 0;
        },
        addCategory(){
            let newCategory = {
                Name: null,
                Code: null,
                Order: 0,
                AlwaysShow: false,
                Default: false,
                IsHidden: false,
                AddSessionsToCategoryCodes: []
            };

            // let newCategory = new Category();
            newCategory.VisibleToAttendeeCategories = [];
            newCategory.Code = this.createUniqueCode();
            for(var i = 0; i < this.show.AttendeeCategories.length; i++){
                newCategory.VisibleToAttendeeCategories.push({Name: this.show.AttendeeCategories[i].Name, Code:this.show.AttendeeCategories[i].Code, Selected: false})
            }

            this.editVendorCategories.push(newCategory);
        },
        async onDelete(){
            this.submitting = true;
            try{
                let r = await this.tryDelete(`/api/vendor/${this.show.Code}/category/${this.editVendorCategory.Code}`);
                    
                if(r.Errors.length === 0) {
                    this.deleteCategory(this.editVendorCategory);
                    this.save();
                }
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },
        deleteCategory(category){
            this.show.VendorCategories.splice(this.show.VendorCategories.indexOf(category), 1);
        },
        async save(){
            this.submitting = true;
            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            this.show.VendorCategories = this.editVendorCategories;
           
            let toSave = [];

            this.editVendorCategories.forEach(c => {
                let newCate = {
                    Code: c.Code,
                    Name: c.Name,
                    AlwaysShow: c.AlwaysShow,
                    Default: c.Default,
                    IsHidden: c.IsHidden,
                    AddSessionsToCategoryCodes: c.AddSessionsToCategoryCodes,
                    VisibleToAttendeeCategories: []
                };

                toSave.push(newCate);

                if(c.VisibleToAttendeeCategories) {
                    c.VisibleToAttendeeCategories.forEach(cc => {
                        newCate.VisibleToAttendeeCategories.push({
                            Code: cc.Code
                        })
                    });
                }
            });

            try{
                let r = await this.tryPost(`/api/vendor/${this.show.Code}/category`,
                    JSON.stringify(toSave), 'application/json');
                    
                if(r.Errors && r.Errors.length == 0) {      
                    this.bus.$emit('Do-Show-Reload');
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
        }
    }
}
</script>