<script>
    export default {
        name: 'vue-title',
        props: ['title'],
        created () {
            document.title = `${this.title} | Tractus Admin Console`;
        },
        watch: {
            title () {
                // only used when the title changes after page load
                document.title = `${this.title} | Tractus Admin Console`;
            }
        },
        render () {
        },
    }
</script>