<template>
    <div>
        <router-view :showDetails="show" :showCode="showCode"></router-view>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],   

    watch: {
        showDetails(to, from) {
            this.show = to;
        }
    },

    methods: {
    },

    data() {
        return {
            show: null           
        }
    },

    created: function() {
        this.show = this.showDetails;   
    },

    errorCaptured (err, vm, info) {
        this.error = `${err.stack}\n\nfound in ${info} of component`;
        console.log(this.error);
        return false;
    }
}
</script>