<template>
    <div @click="asset.IsDirectory ? changePath() : selectFile()"
         class="d-flex">
        <i :class="getIcon()"
            class="ps-1"></i>
        <div class="text-truncate ms-1">
            {{asset.Name}}
        </div>
    </div>
</template>
<script>
export default{
    // name: 'LabelEdit',
    props: ['asset'],
	data: function(){
		return {
            buffer: '',
		}
    },
	methods: {
        getFileExtension(file){
            var split = file.split(".");
            if( split.length === 1 || ( split[0] === "" && split.length === 2 ) ) {
                return "";
            }
            return split.pop().toLowerCase(); 
        },
        getIcon(){
            if(this.asset.IsDirectory == true){
                return "bi bi-folder";
            }

            var begin = "";
            var icon = "";
            var end = "";

            var fileExtension = this.getFileExtension(this.asset.Name);

            switch(fileExtension){
                case "png":
                case "jpg": 
                case "jpeg":
                case "tiff":
                case "gif":
                case "webp":
                    // icon = "fa-image";
                    icon = "bi bi-file-earmark-image";
                    break;
                case "mp4":
                    icon = 'bi bi-filetype-mp4'
                    break;
                case "doc":
                case "docx":
                    // icon = "fa-file-word";
                    icon = "bi bi-file-word";
                    break;
                case "xls":
                case "xlsx":
                    icon = "bi bi-file-excel";
                    break;
                case "pdf":
                    icon = "bi bi-file-earmark-pdf";
                    break;
                case "ppt":
                case "pptx":
                    icon = "bi bi-file-earmark-ppt"
                    break;
                default: 
                    icon = "bi bi-file-earmark";
            }

            // if(icon != ""){
            //     begin = 'fa';
            //     end = 'fa-2x';
            // }

            
            // return `${begin} ${icon} ${end}`;
            return icon;
        },
        changePath(){
            this.$emit('changePath',this.asset.Path);
        },
        selectFile(file){
            this.$emit("selectFile",this.asset.Path);
        },
	},
}
</script>