<template>
    <div>                   
        <p class="fw-bold m-0 p-0">
            {{session.Name}}
        </p>
        <div v-if="!vendor">
            <small>{{session.SessionStartDateTime |  moment('dddd, MMMM Do h:mm a')}} to {{session.SessionEndDateTime |  moment('dddd, MMMM Do h:mm a')}} </small>
            <small v-if="duration">({{duration}} minutes) </small>
        </div>
        <div>
            <small class="badge rounded-pill bg-primary">
                {{categoryName}}
            </small>
            <small> 
                {{session.Location}} 
            </small>
            <small>({{session.Code}})</small>
        </div>
    </div>
</template>
<script>
import moment from 'moment'

export default {
    props: ['session','vendor','showDetails'],
     data() {
        return {
            duration: null  
        }
    },
    computed:{
        categoryName() {
            if(!this.session.Category) {
                return;
            }
            if(this.vendor){
                let vendorCategory = this.showDetails.VendorCategories.find(x => x.Code == this.session.Category.Code);
                if(vendorCategory){
                    return vendorCategory.Name;
                }
                return "";
            }
            else{
                let sessionCategory = this.showDetails.SessionCategories.find(x => x.Code == this.session.Category.Code);
                if(sessionCategory){
                    return sessionCategory.Name;
                }
                return "";
            }

        }
    },
    created: function() {
  
        this.duration = Math.abs(moment(this.session.SessionStartDateTime).diff(moment(this.session.SessionEndDateTime),'minutes'));
    }
}
</script>