<template>
    <loading v-if="submitting">
    </loading>

    <div v-else>
        <nav-bar></nav-bar>
        <vue-title :title="'E-Mail Setup'"></vue-title>
        <div class="container-fluid">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                Domain Name
                            </th>
                            <th>
                                Global
                            </th>
                            <th>
                                Use Catch-All
                            </th>
                            <th>
                                Catch-All Address
                            </th>
                            <th>
                                BCC
                            </th>
                            <th>
                                From
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="domain in domains">
                            <tr :key="domain._Key"
                                @click="changeSelectedDomain(domain)">

                                <td>
                                    <input v-model="domain.DomainName"
                                            class="form-control"> 
                                </td>
                                <td>
                                    <input v-model="domain.IsGlobal"
                                            class="form-check-input"
                                            type="checkbox"
                                            @click.stop>
                                </td>
                                <td>
                                    <input v-model="domain.UseCatchAllAddress"
                                            class="form-check-input"
                                            type="checkbox"
                                            @click.stop>
                                </td>
                                <td>
                                    <input v-model="domain.CatchAllAddress"
                                            class="form-control"
                                            @click.stop>                                    
                                </td>
                                <td>
                                    <input v-model="domain.BccAddresses"
                                            class="form-control"
                                            @click.stop>                                    
                                </td>
                                <td>
                                    <input v-model="domain.FromEmail"
                                            class="form-control"
                                            @click.stop>                                    
                                </td>
                            </tr>   
                            <tr v-if="selectedDomain == domain">
                                <td colspan="7"
                                    v-if="selectedDomainLoading">
                                    <loading></loading>
                                </td>
                                <td v-else
                                    colspan="7">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col">
                                                <h5>
                                                    <span v-if="selectedDomainDetails"
                                                          class="badge"
                                                          :class="{'text-bg-warning': selectedDomainDetails && selectedDomainDetails.State == 'unverified', 'text-bg-success': selectedDomainDetails && selectedDomainDetails.State == 'active'}">
                                                        {{selectedDomainDetails.State}}
                                                    </span>
                                                    <span v-else
                                                          class="badge text-bg-primary">
                                                        New
                                                    </span>
                                                    {{domain.DomainName}}
                                                </h5>


                                                <div class="btn-group">
                                                    <button class="btn btn-success btn-sm"
                                                            @click="saveDomainDetails(selectedDomain)">
                                                        Save
                                                    </button>

                                                    <button class="btn btn-primary btn-sm"
                                                            v-if="selectedDomainDetails && selectedDomainDetails.State != 'active'"
                                                            @click="tryVerifyDomain(selectedDomain)">
                                                        Verify Domain
                                                    </button>

                                                    <button class="btn btn-primary btn-sm"
                                                            v-if="selectedDomainDetails"
                                                            @click="copyDomainDetailsToClipboard(selectedDomain)">
                                                        Copy DNS to Clipboard
                                                    </button>

                                                    <button class="btn btn-danger btn-sm"
                                                            @click="deleteDomain(selectedDomain)">
                                                        Delete Domain
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-if="selectedDomainDetails">
                                            <div class="col">
                                                <h5>Outbound DNS</h5>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Record Type
                                                                </th>
                                                                <th>
                                                                    Name
                                                                </th>
                                                                <th>
                                                                    Value
                                                                </th>
                                                                <th>
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="entry in selectedDomainDetails.SendingDnsEntries">
                                                                <td>
                                                                    {{entry.RecordType}}
                                                                </td>
                                                                <td>
                                                                    <input readonly 
                                                                        class="form-control"
                                                                        @click="copyValueToClipboard(entry.Name)"
                                                                        v-model="entry.Name">
                                                                </td>
                                                                <td>
                                                                    <input readonly 
                                                                        class="form-control"
                                                                        @click="copyValueToClipboard(entry.Value)"
                                                                        v-model="entry.Value">
                                                                </td>
                                                                <td>
                                                                    {{entry.Valid}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <h5>Inbound DNS</h5>                                            
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Record Type
                                                                </th>
                                                                <th>
                                                                    Value
                                                                </th>
                                                                <th>
                                                                    Priority
                                                                </th>
                                                                <th>
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="entry in selectedDomainDetails.ReceivingDnsEntries">
                                                                <td>
                                                                    {{entry.RecordType}}
                                                                </td>
                                                                <td>
                                                                    <input readonly 
                                                                        class="form-control"
                                                                        @click="copyValueToClipboard(entry.Value)"
                                                                        v-model="entry.Value">
                                                                </td>
                                                                <td>
                                                                    <input readonly 
                                                                        class="form-control"
                                                                        @click="copyValueToClipboard(entry.Priority)"
                                                                        v-model="entry.Priority">
                                                                </td>
                                                                <td>
                                                                    {{entry.Valid}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </td>
                            </tr>                 
                        </template>                    
                        <tr>
                            <td colspan="7">
                                <button @click="addNewDomain"
                                        class="btn btn-sm btn-primary">
                                    Add...
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { 
    getApiUrl
} from './environment.hci'

export default {
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            domains: [],
            selectedDomain: null,
            selectedDomainLoading: false,
            selectedDomainDetails: null,
        }
    },

    methods: {
        async saveDomainDetails(domain, domainDetails) {
            this.submitting = true;

            try {
                let toSave = JSON.stringify(domain);

                await this.tryPost('/api/emailsettings/domain/update', toSave, "application/json");
            } catch(ex) {
                this.errors = [ex];
            }

            this.build();
        },

        async tryVerifyDomain(domain) {

            this.selectedDomainLoading = true;

            this.errors = [];

            try {
                let result = await this.tryGet(`/api/emailsettings/domain/${domain.DomainName}/validate`);

                await this.changeSelectedDomain(domain);
            } catch(ex) {
                this.errors = [ex];
            }

            this.selectedDomainLoading = false;
        },

        copyDomainDetailsToClipboard() {
            let toCopy = `Tractus Mailer DNS Setup for ${this.selectedDomain.DomainName}

Outbound DNS:
`;

            this.selectedDomainDetails.SendingDnsEntries.forEach(dns => {
                toCopy += `Record Type: ${dns.RecordType}\r\n${dns.Name}\r\n${dns.Value}\r\n\r\n`;
            });

            navigator.clipboard.writeText(toCopy);
        },

        async deleteDomain(domain) {

        },

        copyValueToClipboard(value) {
            navigator.clipboard.writeText(value);
        },

        addNewDomain() {
            this.domains.push({
                DomainName: "newdomain.com",
                IsGlobal: false,
                UseCatchAllAddress: false,
                CatchAllAddress: '',
                BccAddresses: '',
                FromEmail: 'Tractus Event Passport <noreply@tractusevents.com>',
                _IsNew: true,
                _Key: this.createUniqueCode()
            });

            this.changeSelectedDomain(this.domains[this.domains.length - 1]);
        },

        async changeSelectedDomain(domain) {
            this.selectedDomain = domain;

            if(!this.selectedDomain) {
                return;
            }

            if(domain._IsNew) {
                return;
            }

            this.selectedDomainLoading = true;

            this.errors = [];

            try {
                let result = await this.tryGet(`/api/emailsettings/domain/${domain.DomainName}`);

                this.selectedDomainDetails = result.Result;
            } catch(ex) {
                this.errors = [ex];
            }

            this.selectedDomainLoading = false;
        },

        async build() {
            this.submitting = true;
            this.errors = [];

            try {
                let result = await this.tryGet('/api/emailsettings/domains');
                
                result.Result.forEach(d => Vue.set(d, '_Key', this.createUniqueCode()));

                this.domains = result.Result;
            } catch(ex) {
                this.errors = [ex];
                this.domains = [];
            }

            this.submitting = false;
        }
    },

    created() {
        this.build();
    },
}
</script>