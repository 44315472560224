<template>
    <loading v-if="submitting">

    </loading>
    <div v-else class="scrolling-columns">
        <div class="row" v-if="!submitting">
            <div class="col-md-3">

                <nav class="mb-3 mt-2 d-flex align-items-center space-between">
                    <span class="flex-fill fs-5">
                        Attendee Categories ({{allItems.length}})
                    </span>

                    <div class="d-flex">

                        <button class="btn btn-outline-primary"
                                @click="showAddNew">
                            New...
                        </button>
                    </div>
                </nav>                
                <div class="list-group-item list-group-item-action px-1 py-0"
                     v-for="(item, idx) in allItems"
                     @mousedown="onMouseDown"
                     @click="showEditMenu(item,idx)">
                    <a :class="{'container': idx == activeIndex}">
                        <!-- add here -->
                        <div class="row align-items-center">
                            <div class="col-md-auto d-lg-block">
                                <p class="pb-0 mb-0 fw-bold">
                                    {{item.Name}} 
                                </p>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col d-lg-block">
                                <p class="pb-0 mb-0">
                                {{item.Code}}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>

                <nav class="mt-2 d-flex align-items-center space-between">
                    <span class="flex-fill fs-5">
                        Discussion Permissions
                    </span>

                    <div class="d-flex">

                        <button class="btn btn-outline-primary"
                                @click="saveDiscussionPermissions">
                            Save
                        </button>
                    </div>
                </nav>                

                <div class="mt-2"
                     v-if="loadingDiscussionPermissions">
                    <loading></loading>
                </div>
                <div class="mt-2"
                     v-else>
                    <div v-for="item in discussionPermissions"
                         :key="item.CategoryCode"
                         class="mb-2">
                        <h6>
                            {{getCategoryName(item.CategoryCode)}}
                        </h6>

                        <label class="form-check-label">
                            <input v-model="item.CanInviteOthers"
                                    class="form-check-input"
                                    type="checkbox">
                            Can invite others to chats
                        </label>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col" v-if="showEdit">
                        <attendee-category-editor   @saved="onSave" 
                                                    @cancel="onCancel"
                                                    @deleted="onSave"
                                                    :show="showDetails"
                                                    :attendeeCategory="currentItem"
                                                    :isNewCategory="isNew">
                        </attendee-category-editor> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import Category from '../models/Category'
export default {
    
    props: ['showDetails'],

    mixins: [
        Token,
        Common
    ],   

    methods: {  
        showAddNew(){
            this.resetItems();
            this.activeIndex = null;
            this.isNew = true;
            this.currentItem = new Category();

            this.showSelect = false;
            this.showEdit = true;
        },

        showEditMenu(request, idx){
            this.activeIndex = idx;
            this.showSelect = false;
            this.showEdit = true;
            this.currentItem = request;
        },

        resetItems(){
            this.showEdit = false;
            this.showSelect = false;
            this.isNew = false;
        },

        reloadAndClear(){
            this.reload();
            this.resetItems();
            this.activeIndex = null;
        },

        onSave() {
            this.bus.$emit('Do-Show-Reload');
            this.reloadAndClear();
        },

        onCancel() {
            if(this.isNew){
                this.resetItems();
                return;
            }
            this.showSelect = true;
            this.showEdit = false;
            this.activeIndex = null;
        },

        onMouseDown(){
            this.resetItems();
            this.currentItem = null;
        },

        reload() {
            this.submitting = true;
            this.allItems = this.showDetails.AttendeeCategories;
            this.submitting = false;

            this.loadDiscussionPermissions();
        },

        getCategoryName(code) {
            let category = this.showDetails.AttendeeCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        async loadDiscussionPermissions() {
            this.loadingDiscussionPermissions = true;

            let result = await this.tryGet(`/api/discussionpermissions/${this.showDetails.Code}`);

            this.discussionPermissions = result.Result;

            this.loadingDiscussionPermissions = false;
        },

        async saveDiscussionPermissions() {
            this.loadingDiscussionPermissions = true;

            await this.tryPost(`/api/discussionpermissions/${this.showDetails.Code}`,
                JSON.stringify(this.discussionPermissions),
                'application/json');

            this.loadingDiscussionPermissions = false;
        }
    },

    data() {
        return {
            showEdit: false,
            showSelect: false,

            allItems: [],
            
            currentItem: [],
            pageTitle: null,
            itemType: null,

            isNew:false,
            activeIndex: null,

            discussionPermissions: [],
            loadingDiscussionPermissions: true,
        }
    },

    created: function() {
        this.pageTitle = 'Categories';
        this.itemType = 'Category';
        this.reload();
    },
}
</script>