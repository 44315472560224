<template>
    <form class="form-horizontal">
        <div class="modal fade" 
            id="deleteSessionWarn"
             ref="deleteSessionWarn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            Delete SessionCategory
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete {{editSessionCategory.Name}}? This will delete all sessions linked to it and can't be undone.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <!-- data-bs-dismiss="modal" -->
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                @click="OnDelete">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 col-form-label">
                Category Name
            </label>
            <div class="col-sm-10">
                <input  :disabled="submitting" 
                        :placeholder="'Name'"
                        class="form-control"
                        v-model="editSessionCategory.Name" />
            </div>
        </div>
        <h5 class="mb-1 pb-0">Category items will be seen by...</h5>                                 
        <div class="form-check">
            <input  :disabled="submitting" 
                    class="form-check-input"
                    type="checkbox"
                    v-model="editSessionCategory.AlwaysShow" />
            <label class="form-check-label">
                Visible to Everyone
            </label>
        </div>
        <div class="form-check">
            <input  :disabled="submitting" 
                    class="form-check-input"
                    type="checkbox"
                    v-model="editSessionCategory.IsHidden" />
            <label class="form-check-label">
                Hidden/Invisible Tag/Property
            </label>
        </div>
        <div v-for="aCategory in editSessionCategories">
            <div class="form-check">
                <input class="form-check-input"
                    type="checkbox" 
                    v-model="aCategory.Selected">
                <label class="form-check-label">
                    {{aCategory.Name}}
                </label>
            </div> 
        </div> 
        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>

        <hr>

        <div class="mt-2">
            <button type="button" @click="OnCancel"
                    class="btn btn-secondary">
                Cancel
            </button>


            <div class="btn btn-danger"
                v-if="!isNewCategory"
                data-bs-toggle="modal"
                data-bs-target="#deleteSessionWarn">
                <div class="d-flex">
                    <i class="fa pe-1"></i>
                    <h6 class="mb-0">
                        Delete
                    </h6>
                </div>
            </div>

            <button type="button" @click="addCategory"
                    class="btn btn-success">
                Save
            </button>
        </div>              
    </form>
</template>
<script>

import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import Category from '../../models/Category'

export default{
    props: ['show','sessionCategory','isNewCategory'],
    mixins: [
        Token,
        Common
    ],
    data() {

        return {
            editSessionCategory: null,
            editShow:null,
            editSessionCategories: null,
            validCode: true
        }
    },
    created: function(){
        this.editSessionCategory = this.cloneObject(this.sessionCategory);
        this.editShow = this.cloneObject(this.show)
        this.editSessionCategories= [];
        for(var i = 0; i < this.show.AttendeeCategories.length; i++){

            this.editSessionCategories.push({Name: this.show.AttendeeCategories[i].Name, Code:this.show.AttendeeCategories[i].Code, Selected: false})
        }
        for(var i = 0; i < this.editSessionCategory.VisibleToAttendeeCategories.length; i++){
            for(var j = 0; j < this.show.AttendeeCategories.length; j++){
                if(this.editSessionCategory.VisibleToAttendeeCategories[i].Code === this.editSessionCategories[j].Code){
                    this.editSessionCategories[j].Selected = true;
                }
            }
        }
    },
    methods:{

        validateForm: function() {

            this.validateErrors = [];
            this.validCode=true;

            //Check if session code is unique 
            if(this.editSessionCategory.Code && this.isNewCategory){
                for(var i = 0; i < this.show.SessionCategories.length; i++){
                    if(this.editSessionCategory.Code.toUpperCase() == this.show.SessionCategories[i].Code.toUpperCase()){
                        this.validCode =false; 
                    }
                }
            }
            if(!this.validCode){
                this.validateErrors.push("Another Session Category with the same Code already exists.");
                
            }
            var str = this.editSessionCategory.Name;
            if (this.editSessionCategory.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Session Category Name cannot be only spaces.");
            }
            if(!this.editSessionCategory.Name) {
                this.validateErrors.push("Session Category Name cannot be blank.");
            }
            if(!this.editSessionCategory.Code) {
                this.validateErrors.push("Session Category Code cannot be blank.");
            }
            
            return this.validateErrors.length === 0;
        },
        async addCategory(){
            this.submitting = true;

            this.editSessionCategory.VisibleToAttendeeCategories = [];

            for(var i = 0; i < this.editSessionCategories.length; i++){
                var category = new Category();
                if(this.editSessionCategories[i].Selected){
                    category.Name = this.editSessionCategories[i].Name
                    category.Code = this.editSessionCategories[i].Code
                    this.editSessionCategory.VisibleToAttendeeCategories.push(category)
                } 
            }
            
            if(!this.validateForm()) {
               
                this.submitting = false;
                return;
            }
            
            this.editSessionCategory.Code = this.editSessionCategory.Code;
            
            // Update session category if not new
            var index = this.show.SessionCategories.indexOf(this.sessionCategory);

            if(index == -1) {
                this.editShow.SessionCategories.push(this.editSessionCategory);
            } else {
                this.editShow.SessionCategories.splice(index, 1, this.editSessionCategory);
                
            }     

            try{
                let r = await this.tryPost(`/api/session/${this.show.Code}/category`, 
                    JSON.stringify(this.editShow.SessionCategories), 
                    'application/json');
                    
                if(r.Errors && r.Errors.length == 0) {                  
                    this.$emit('saved', r.Result);
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            

        },
        async OnDelete(){
            this.submitting = true;
            try{
                let r = await this.tryDelete(`/api/session/${this.show.Code}/category/${this.editSessionCategory.Code}`);
                    
                if(r.Errors.length === 0) {
                    this.deleteCategory();
                }
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },

        async deleteCategory(){
            this.submitting = true;
            var index = null;
            
            var findResult = this.show.SessionCategories.find(o => o.Code == this.editSessionCategory.Code);
            if(findResult){
                index= this.show.SessionCategories.indexOf(findResult);
            }
            else{
                //throw error
                return;
            }

            this.editShow.SessionCategories.splice(index,1);

            var toSave = JSON.parse(JSON.stringify(this.editShow));

            try{
                let r = await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
                    
                if(r.Errors && r.Errors.length == 0) {
                    this.$emit('saved', r.Result);
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            
        },
        OnCancel(){
            this.submitting = false;
            this.$emit('cancel');
        },
    }
}
</script>