<template>
    <div class="form-floating">
        <select class="form-select"
                v-model="selectedItem"
                @change="onChange()">
            <option selected :value="null">{{placeholder || '(Please Select...)'}}</option>
            <optgroup v-for="(destinationGroup, key) in destinations"
                      :label="key">
                <option v-for="item in destinationGroup" :value="item" :key="item.Code">{{item.EditorName}}</option>
            </optgroup>
        </select>
        <label>Select Destination</label>
    </div>
</template>
<script>

// function createTractusLinkFromElement(element, showDetails) {
//         return `tractus://${element.ElementTypeCode}?s=${showDetails.Code}&c=${element.Code}`;
// };

export default {
    props: [
        'showDetails', 
        'value',
        'displayText',
        'code',
        'elementTypeCode',
        'placeholder',
        'detailText',
        'destinations'
    ],

    data() {
        return {
            selectedItem: null,
            items: []
        }
    },

    watch: {
        value(newValue) {
            this.syncLookup(newValue);
        }
    },

    methods: {
        syncLookup(value) {
            var item = Object.values(this.destinations).flat().find(x=> x.TractusLink == value);
            
            if(!item) {
                this.selectedItem = null;
            } else {
                this.selectedItem = item;
            }
        },

        onChange() {
            let newLink = null;
            let newText = null;
            let code = null;
            let elementTypeCode = null;

            if(this.selectedItem) {
                newLink = this.selectedItem.TractusLink;
                newText = this.selectedItem.DisplayName;
                code = this.selectedItem.Code;
                elementTypeCode = this.selectedItem.EntityTypeCode;
            } 

            let newDescription = this.selectedItem.Description || '';

            this.$emit("update:value", newLink);
            this.$emit("update:displayText", newText);

            this.$emit('update:detailText', newDescription);

            this.$emit("update:elementTypeCode", elementTypeCode);
            this.$emit("update:code", code);
        }
    },



    mounted() {
        this.syncLookup(this.value);
    }
}
</script>