export function getApiUrl() {
    return process.env.VUE_APP_API_ENDPOINT;
}

export function getPollAppUrl() {
    return process.env.VUE_APP_POLL_APP_ENDPOINT;
}

export function getAttendeeAppUrl() {
    return process.env.VUE_APP_ATTENDEE_APP_URL;
}

export function getVideoApiUrl() {
    return process.env.VUE_APP_VIDEO_API_URL;
}

export function getLandingAppUrl() {
    return process.env.VUE_APP_LANDING_APP_URL;
}