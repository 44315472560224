import { render, staticRenderFns } from "./push.notification.list.vue?vue&type=template&id=06496c95&scoped=true"
import script from "./push.notification.list.vue?vue&type=script&lang=js"
export * from "./push.notification.list.vue?vue&type=script&lang=js"
import style0 from "./push.notification.list.vue?vue&type=style&index=0&id=06496c95&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06496c95",
  null
  
)

export default component.exports