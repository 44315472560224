<template>
    <div class="container-fluid" v-if="!submitting">
        <div class="row">
            <div class="col">
                <h1>Inbound WebHooks</h1>


                <ul class="list-group">
                    <li class="list-group-item"
                        @click="addWebHook">
                        Add...
                    </li>
                    <li class="list-group-item"
                        @click="saveWebHooks">
                        Save Changes
                    </li>

                    <li v-for="hook in webhooks"
                        class="list-group-item"
                        :class="{'text-decoration-line-through' : hook.IsDeleted}">
                        <div class="row">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input class="form-control" type="url" v-model="hook.Description" placeholder="Name">
                                    <label>Name</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <input class="form-control" type="url" v-model="hook.NotifyEmailAddresses" placeholder="Name">
                                    <label>Notify Emails</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <select class="form-select" v-model="hook.EmailTemplateCode">
                                        <option selected :value="''">(Please Select...)</option>
                                        <option v-for="qt in templates" 
                                                :value="qt.Code"
                                                :key="qt.Code">
                                            {{qt.Subject}} 
                                        </option>
                                    </select>
                                    <label>E-Mail Template</label>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="copyWebHookUrl(hook)"
                                        :disabled="!hook.Code"
                                        class="btn btn-danger">
                                    Copy URL
                                </button>
                            </div>
                            <div class="col-md-auto">
                                <button @click="handleHookDelete(hook)"
                                        class="btn btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="row">

                </div>
            </div>
        </div>
    </div>
    <div v-else
         class="container-fluid vh-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import Vue from 'vue';
import Toastify from 'toastify-js'

export default {
    props: ['showCode'],

    mixins: [
        Token,
        Common,
    ],   

    data() {
        return {
            webhooks: [],
            templates: [],
        }
    },

    methods: {
        addWebHook() {
            this.webhooks.push({
                ShowCode: this.showCode,
                Code: null,
                IsDeleted: false,
                NotifyEmailAddresses: '',
                EmailTemplateCode: '',
                Description: 'New Web Hook'
            });
        },

        handleHookDelete(hook) {
            if(!hook.Code) {
                let hookIndex = this.webhooks.indexOf(hook);

                if(hookIndex != -1) {
                    this.webhooks.splice(hookIndex, 1);
                }
            } else {
                hook.IsDeleted = !hook.IsDeleted;
            }
        },

        copyWebHookUrl(hook) {
            navigator.clipboard.writeText(`${this.getApiUrl()}/api/inboundwebhook/${this.showCode}/${hook.Code}`);

            Toastify({
                text: "Copied webhook URL to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            });
        },

        async saveWebHooks() {
            this.submitting = true;

            let result = await this.tryPost(`/api/inboundwebhooks/${this.showCode}`, JSON.stringify(this.webhooks), 'application/json');

            this.webhooks = result.Result;

            this.submitting = false;
        },

        async build() {
            this.submitting = true;

            let result = await this.tryGet(`/api/inboundwebhooks/${this.showCode}`);

            this.webhooks = result.Result;

            let r = await this.tryGet(`/api/communications/templates/${this.showCode}`);

            this.templates = r.Result;

            this.submitting = false;
        }
    },

    async created() {
        this.build();
    },
    
    // beforeDestroy() {
    //     if(this.modal){
    //         this.modal.dispose();
    //     }
    // },
}
</script>