// Tractus.Data.PollQuestionAnswer
// Generated on 2019-11-12 12:53:01 PM by Elias

class PollQuestionAnswer {
	//public String DisplayText
	DisplayText = null;
	//public String Response
	Response = null;
	//public Boolean IsCorrect
	IsCorrect = false;
}
export default PollQuestionAnswer
