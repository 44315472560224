<template>
    <div>
        <div class="modal fade" 
                id="filePicker"
                ref="filePicker">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb default-color">
                                <li v-for="(crumb,idx) in crumbs"
                                    class="breadcrumb-item {'breadcrumb-item active': (idx + 1) == crumbs.length">
                                    <a v-if="(idx + 1) != crumbs.length" @click="changePath(getCrumbPath(crumb))">{{crumb}}</a>
                                    <label v-if="(idx + 1) == crumbs.length">{{crumb}}</label>
                                </li>
                            </ol>
                        </nav>
                        <button type="button" class="btn-close" @click="hideModal"></button>
                    </div>
                    <div class="modal-body file-upload-modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-9">
                                    <div class="row row-cols-sm-1 row-cols-lg-2 row-cols-xxl-5">
                                        <div v-for="asset in assets" class="col">
                                            <asset-list-item :asset="asset"
                                                            class="asset-item file-picker-item"
                                                            :class="{ 'selected-image': asset.Path == editImagePath }"
                                                            @changePath="changePath"
                                                            @selectFile="selectFile" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 border-start">
                                    <div class="d-flex flex-column position-sticky top-0">
                                        <h6 >Current Selection</h6>
                                        <img class="img-fluid img-upload-preview" 
                                            :src="editImagePath" />
                                        <button class="btn btn-primary"
                                                type="button"
                                                @click="onCopy">
                                                Copy Url
                                        </button>
                                        <hr/>
                                        <h6>Upload New File</h6>
                                        <div>
                                            <file-upload :acceptFileType="searchFilters" 
                                                :assetType="fixedPath"
                                                :showCode='showCode'
                                                @uploadSuccess="onSaveImageComplete">
                                            </file-upload>
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                @click="openNewFolderPicker"
                                class="btn btn-primary">
                            New Folder
                        </button>
                        <button type="button" 
                                class="btn btn-secondary" 
                                @click="hideModal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="onConfirmClick">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" 
                id="newFolder"
                ref="newFolder">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" @click="onCancel"></button>
                    </div>
                    <div class="modal-body file-upload-modal-body">
                        <input v-model="newFolderName" 
                               placeholder="New Folder Name ..." 
                               type="text"
                               class="form-control">
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                @click="onCancel">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="onNewFolderConfirm">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<style scoped>
.file-picker-item{
    overflow: hidden;
    overflow-wrap: break-word;
}
.img-upload-preview {
    max-width: 150px;
    max-height: 150px;
}
.selected-image {
    background: #666;
}
.asset-item:hover {
    background: #333;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier();
}
.asset-item {
    cursor: pointer;
    padding: 0.5em 0;
}

.file-upload-modal-body {
    overflow-y: auto;
}
</style>
<script>

const documentFilters = ["doc", "docx", "xls", "xlsx", "pdf", "ppt", "pptx"];
const imageFilters = ["png","jpg","jpeg","tiff","gif"];

import { Modal } from 'bootstrap'
import Token from './authentication/token'
import Common from './common'

export default {
    props: [ 'imagePath', 'showCode', 'type'],
    mixins: [
        Token
    ],

    computed:{
        fixedPath(){
            return this.path.split(`/content/${this.showCode}/`)[1];
        },

        searchFilters() {
            if(!this.type){
                return "";
            }
            return this.type == 'document'
                ? documentFilters.map(x=>`.${x}`).join(',')
                : imageFilters.map(x=>`.${x}`).join(',');
        }
    },

    watch: {
        showCode: function(newCode) {
            this.loadAssets("");
            this.editImagePath = this.imagePath;
        },
        type:function(newCode){
            this.loadAssets("");
        }

    },

    data(){
        return{
            assets: [],
            path: '',
            crumbs: ['Home'],
            submitting: false,
            editImagePath: "",
            modal: null,
            newFolderModal: null,
            newFolderName: '',
        }
    },

    methods: {
        hideModal(){
            this.modal.hide();
        },
        onCopy(){
            if(this.editImagePath){
                navigator.clipboard.writeText(this.editImagePath);
            }
        },
        openNewFolderPicker(){
            if(!this.newFolderModal) {
                this.newFolderModal = new Modal(this.$refs.newFolder);
            }
            
            this.newFolderModal.show();
        },
        onCancel(){
            this.newFolderModal.hide();
        },
        onNewFolderConfirm(){
            if(this.newFolderName[this.newFolderName.length - 1] != "/"){
                this.newFolderName = this.newFolderName + "/";
            }
            this.loadAssets(this.path + this.newFolderName);
            this.newFolderName = "";
            this.newFolderModal.hide();
        },
        async loadAssets(path){
            this.submitting = true;
            this.crumbs = ['Home'];
            this.path = path;
            
            if(path != ""){
                path = unescape(path);
                var find = `/content/${this.showCode}/`;
                var index = path.indexOf(find);
                if(index != -1){
                    index += find.length;
                } 
                var pathToSplit = path.substring(index);
                path = pathToSplit;

                var split = pathToSplit.split('/');

                if(split.length > 0){
                    for(var i = 0; i < split.length; i++){
                        this.crumbs.push(split[i]);
                    }
                }
            } else {
                this.path = `/content/${this.showCode}/`;
            }


            var filters = "";
            if(this.type){
                filters = this.type;
            }            

            try{
                let r = await this.tryGet(`/api/assets?showCode=${this.showCode}&path=${path}&filters=${filters}`)
                    
                var sortedResults = r.Result;
                console.log(sortedResults);

                sortedResults.sort(function(a,b){
                    if(a.IsDirectory > b.IsDirectory){
                        return -1;
                    }
                    if(a.IsDirectory < b.IsDirectory){
                        return 1;
                    }
                    
                    return a.Name.localeCompare(b.Name);
                });
                
                for(let i = 0; i < sortedResults.length; i++){
                    let result = sortedResults[i];
                    result.Path = unescape(result.Path);
                    result.Name = unescape(result.Name);
                    result.Name = result.Name.replace(`${this.showCode}/`,'');
                    
                    if(this.crumbs.length > 1){
                        for(let j = 1; j < this.crumbs.length; j++){
                            if(this.crumbs[j]){
                                result.Name = result.Name.replace(`${this.crumbs[j]}/`,"" );
                            }
                        }
                    }
                }

                this.assets = sortedResults;

            }
            catch(ex){

            }
            this.submitting = false;
                
        },
        openPicker(){
            if(!this.modal) {
                console.log("no modal");
                this.modal = new Modal(this.$refs.filePicker);
            }
            console.log("modal show");
            this.modal.show();
            console.log("modal show after");
        },
        onSaveImageComplete(result) {
            this.editImagePath = result.Result[0];
            this.loadAssets(this.path);
        },
        changePath(path){
            if(path[path.length - 1] != "/"){
                path = path + "/";
            }
            this.loadAssets(path);
        },
        selectFile(file){
            console.log('FILE SELECTED --- ', file);
            this.editImagePath = file;
            this.$emit("fileSelected",file);
        },
        onConfirmClick() {
            this.modal.hide();
            this.$emit("fileSelected",this.editImagePath);
        },
        getCrumbPath(crumb){
            var index = this.crumbs.indexOf(crumb);

            var path = [];
            for(var i = 1; i <= index; i++){
                path.push(this.crumbs[i]);
            }
            
            return `/content/${this.showCode}/` + path.join('/')
        },
    },

    mounted(){
        this.loadAssets("");
        this.editImagePath = this.imagePath;
    },

    beforeDestroy() {
        if(this.modal){
            this.modal.dispose();
        }
    },

}
</script>
