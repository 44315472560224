<template>
    <loading v-if="submitting">

    </loading>
    <div class="container-fluid scrolling-columns"
         v-else>
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <input placeholder="🔍 Search for Chat"
                               class="form-control"
                               v-model="filter">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-secondary dropdown-toggle form-control" 
                                type="button"
                                data-bs-toggle="dropdown" 
                                aria-expanded="false">
                                View {{ itemFilter == 'All' ? 'All' : 'Non-Private' }}
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <button class="dropdown-item"
                                        type="button"
                                        v-for="item in itemFilters"
                                        @click="setFilterMode(item.Code)">
                                    {{item.Name}}
                                </button>
                            </li>
                        </ul>                        
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary" 
                                type="button"
                                @click="build()">
                            Refresh
                        </button>                        
                    </div>
                    <div class="col-auto">
 
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle form-control" 
                                    type="button"
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                    Add Chat...
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="category in chatTypes">
                                    <button class="dropdown-item" 
                                            type="button"       
                                       @click="showAddNew(category.Code)">
                                       {{category.Name}}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-striped table-sm">
                    <thead class="atn-header border-bottom border-dark bg-body-tertiary">
                        <tr>
                            <th>
                                Name
                            </th>
                            <th v-if="!currentItem">
                                Description
                            </th>
                            <th>
                                Type
                            </th>
                            <th v-if="!currentItem">
                                Anonymous Posts
                            </th>
                            <th v-if="!currentItem">
                                Global Chat
                            </th>
                            <th v-if="!currentItem">
                                Slow Mode
                            </th>
                            <th v-if="!currentItem">
                                Has Related Entity
                            </th>
                            <th v-if="!currentItem">
                                Code
                            </th>
                            <th v-if="!currentItem">
                                Type Code
                            </th>    
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredItems"
                            class="pe-action"
                            :class="[currentItem && currentItem == item ? 'table-primary' : '']"
                            @click="showEditPanel(item)">
                            <td>
                                {{ item.Name }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.Description }}
                            </td>
                            <td>
                                {{ getTypeCodeName(item.TypeCode, item.Code) }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.AllowAnonymous ? 'Yes' : 'No' }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.IsGlobalChat ? 'Yes' : 'No' }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.DelayBetweenMessageSec != 0 ? `${item.DelayBetweenMessageSec} sec` : 'Off' }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.RelatedEntityTypeCode ? 'Yes' : 'No' }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.Code }}
                            </td>
                            <td v-if="!currentItem">
                                {{ item.TypeCode }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-8"
                 v-if="currentItem">
                 <discussion-editor 
                        :discussionArea="currentItem"
                        :show="showDetails"
                        @saved="onSave"
                        @cancel="onCancel"
                        @deleted="onDeleted">
                </discussion-editor>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import DiscussionArea from '../models/DiscussionArea'
import moment from 'moment'
export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],   

    computed: {
        filteredItems() {
            if(!this.filter) {
                return this.allItems;
            }

            let toReturn = this.allItems;

            let searchTest = new RegExp(this.filter, 'i');

            toReturn = toReturn.filter(x => searchTest.test(x.Name));

            return toReturn;
        },
    },        

    methods: {
        getTypeCodeName(typeCode, code) {
            if(code.indexOf('VENDOR_') != -1) {
                return 'Private DM';
            }

            switch(typeCode){
                case 'LiveQa':
                    return "Live Q&A";
                case "GroupChat":
                    return "Group Chat";
                case 'Chat':
                    return "Private DM";
                default:
                    return 'Unknown';    
            }
        },

        setFilterMode(code) {
            this.itemFilter = code;
            this.build();
        },
        showAddNew(typeCode){
            this.resetItems();
            this.isNew = true;
            
            this.currentItem = new DiscussionArea();
            this.currentItem.Id = '0';
            this.currentItem.ShowCode  = this.showCode;
            this.currentItem.TypeCode = typeCode;
           
            this.showEditPanel(this.currentItem);
        },

        showEditPanel(request){
            this.showEdit = true;
            this.currentItem = null;
            Vue.nextTick(() => this.currentItem = request);
        },

        onDeleted(){
            this.build();
            this.showEdit = false;
            this.currentItem = null;
        },
        onCancel() {
            if(this.isNew){
                this.resetItems();
                return;
            }
            this.showEdit = false;
            this.currentItem = null;
        },
        onSave(){
            this.build();
            this.resetItems();
        },

        resetItems(){
            this.showEdit = false;
            this.isNew = false;
        },

        async build(){
            this.submitting = true;
            this.groups = [];
            try{
                let r = await this.tryGet(`/api/discussions/show/${this.showCode}?f=${this.itemFilter}`);
                
                this.allItems = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
        
        },
    },

    data() {
        return {
            pageTitle: null,
            allItems: [],
            filter: '',
            
            currentItem: null,
            isNew: false,

            showEdit: false,
            chatTypes: [{Name: 'Live Q&A', Code: 'LiveQa'},
                        {Name: 'Group Chat', Code: 'GroupChat'}],

            itemFilter: 'ExcludeOneToOne',

            itemFilters: [
                { Name: 'All Chats', Code: 'All'},
                { Name: 'Exclude 1-1 Chats', Code: 'ExcludeOneToOne'}
            ]
        }
    },

    created: function() {
        this.pageTitle = 'Chat Rooms';
        this.itemType = 'Chat';
        this.build();
    },
}
</script>