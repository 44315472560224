<template>
    <loading v-if="submitting">
    </loading>

    <div v-else
         class="container-fluid">

        <h4>E-Mail Settings</h4>

        <div class="btn-group mt-1">
            <button @click="saveSettings"
                    class="btn btn-success btn-sm">
                Save Settings        
            </button>
        </div>
        
        <div class="table-responsive mt-1">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Domain Name</th>
                        <th>From E-Mail</th>
                        <th>Is Catch-All?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr @click="updateSelectedDomain()"
                        :class="{'table-active': !settings.DomainName}">
                        <td>
                            <span v-if="!settings.DomainName">✅</span>
                        </td>
                        <td colspan="3">
                            Do Not Send E-Mails - Sending Will Fail
                        </td>
                    </tr>
                    <tr v-for="domain in domains"
                        @click="updateSelectedDomain(domain)"
                        :class="{'table-active': settings.DomainName == domain.DomainName}">
                        <td>
                            <span v-if="settings.DomainName == domain.DomainName">✅</span>
                        </td>
                        <td>
                            {{domain.DomainName}}
                        </td>
                        <td>
                            {{domain.FromEmail}}
                        </td>
                        <td>
                            <span v-if="domain.UseCatchAllAddress && domain.CatchAllAddress">
                                {{domain.CatchAllAddress}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="form-floating mt-1">
            <select v-model="settings.DomainName"
                    class="form-select">
                <option :value="''">
                    (No Domain Selected - Do Not Send Emails)
                </option>
                <option v-for="domain in domains"
                        :value="domain.DomainName">
                    {{domain.DomainName}} ({{domain.FromEmail}}) <span v-if="domain.UseCatchAllAddress">(Catch-All)</span>
                </option>
            </select>
            <label>Send E-Mails From Domain</label>
        </div>

        <div class="form-floating mt-1">
            <input v-model="settings.ReplyToAddress"
                    class="form-control">
            <label>Reply To Address</label>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { 
    getApiUrl
} from './environment.hci'

export default {
    props: ['showCode'],

    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            domains: [],
            settings: null,
        }
    },

    methods: {
        updateSelectedDomain(domain) {
            if(!domain) {
                this.settings.DomainName = "";
            } else {
                this.settings.DomainName = domain.DomainName;
            }
        },

        async saveSettings() {
            this.submitting = true;

            try {
                let toSave = JSON.stringify({
                    ShowCode: this.showCode,
                    DomainName: this.settings.DomainName,
                    ReplyToAddress: this.settings.ReplyToAddress
                });

                await this.tryPost('/api/emailsettings/show/update', toSave, "application/json");
            } catch(ex) {
                this.errors = [ex];
            }

            this.build();
        },

        async build() {
            this.submitting = true;
            this.errors = [];

            try {
                let result = await this.tryGet(`/api/emailsettings/show/${this.showCode}`);
                
                this.settings = result.Result;
                this.domains = result.Result.AvailableDomains;

            } catch(ex) {
                this.errors = [ex];
                this.domains = [];
            }

            this.submitting = false;
        }
    },

    created() {
        this.build();
    },
}
</script>