// Tractus.Data.PollQuestionDisplayCondition
// Generated on 2019-11-12 12:53:01 PM by Elias

class PollQuestionDisplayCondition {
	//public string ReliesOnQuestionCode
	ReliesOnQuestionCode = null;
	//public String RequiredAnswer
	RequiredAnswer = null;
}
export default PollQuestionDisplayCondition
