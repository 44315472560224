<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <span :class="{'text-muted' : !poll.IsOpenOverride}">{{poll.Name}}<span v-if="!poll.IsOpenOverride"> (Closed)</span></span>

            <div class="btn-group">
                <button type="button"
                        @click.stop="deletePoll"
                        class="btn btn-danger">
                    <i class="bi bi-trash-fill"></i>                    
                </button>
                <a v-if="poll.AllowAnonymousResponse" 
                    target="_blank" 
                    :href="pollLink"
                    @click.stop
                    class="btn btn-success">
                    <i class="bi bi-share-fill"></i>
                </a>
            </div>                        
        </div>
    </div>   
</template>
<script>
import { getPollAppUrl } from '../environment.hci';
export default {
    props:['poll'],

    computed: {
        pollLink: function() {
            return `${getPollAppUrl()}/#/pp/${this.poll.ShowCode}/${this.poll.Code}`;
        },
    },

    methods:{
        deletePoll(){
            this.$emit('delete', this.poll);
        }
    },
}
</script>
