
class AnnouncementRequest {
	// public string Id { get; set; }
	Id = null;
	// public string DisplayMode { get; set; }
	DisplayMode = "SideBar";
	// public string[] Recipients { get; set; }
	Recipients = [];
	// public string Status { get; set; }
	Status = "Pending";
	// public bool SendPushNotification { get; set; }
	SendPushNotification = false;
	// public string Subject { get; set; }
	Subject = null;
	// public string Message { get; set; }
	Message = null;
	// public string DestinationCode { get; set; }
	DestinationCode = null;
	// public string DestinationType { get; set; }
	DestinationType = null;
	// public string ShowCode { get; set; }
	ShowCode = null;
	// public string PrimaryMediaUrl { get; set; }
	PrimaryMediaUrl = null;
	// public string Time { get; set; } 
	Time = null;
}

export default AnnouncementRequest
