// Tractus.Data.Category
// Generated on 2019-11-12 12:53:01 PM by Elias

class Category {
	//public String Name
	Name = null;
	//public String Code
	Code = null;
	//public Int32 Order
	Order = 0;
	//public Boolean AlwaysShow
	AlwaysShow = false;
	//public Boolean Default
	Default = false;
	//public Boolean IsHidden
	IsHidden = false;
    CanAttendeeChoose = false;
    IconUrl = null;
}
export default Category
